import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  filterTreeData,
  isObjectEmpty,
  recursiveFunction,
  recursiveSearchByType,
} from "../../services/utils";
import { endOfDay } from "date-fns";
import { calculateSpreadsOrPlayers } from "./sport.utils";

export interface SportState {
  isFetchingPalimpsest: boolean;
  isFetchingTournament: boolean;
  isFetchingEvent: boolean;
  isFetchingConfig: boolean;
  isFetchingFeatured: boolean;
  isFetchingWidget: boolean;
  favorites?: IPalimpsestItem[];
  sports?: IPalimpsestItem[];
  widgets: number[];
  outrights?: IPalimpsestItem[];
  filteredSportsByTime?: IPalimpsestItem[];
  timeFilter?: ITimeFilter;
  expandedSportsNodes: string[];
  expandedOutrightsNodes: string[];
  selectedNodes: string[];
  groupsConfig?: IGroupConfig[];
  marketsConfig?: IMarketConfig[];
  tournaments: Record<string, ITournament>;
  selectedEvent?: ISelectedEvent;
  eventMarkets?: IMarketData[];
  responsive: string;
  tournamentOrder: number;
  featuredWidget?: IEventData[];
  isWidgetVisible?: boolean;
  isSideBarVisible?: boolean;
  isTournamentViewVisible?: boolean;
  isEventViewVisible?: boolean;
  oddsToPrint: IEventData[];
  featuredSelectedSpread: string;
  sportWidget: any;
  mobileSidebarState: string;
}
export interface ISportWidget {
  events?: IEventData[];
  selectedMarket: string;
  spreads?: Record<string, IOddData[]>;
  selectedSpreads?: Record<
    string,
    number | string | Record<string, Record<string, string | number>>
  >;
  players?: Record<string, Record<string, IOddData[]>>;
  selectedPlayers?: Record<string, Record<string, number | string>>;
  featuredSelectedSpread?: string;
}
export interface IPalimpsestItem {
  id: number;
  label: string;
  type: string;
  icon: string;
  order: number;
  sport_id: number;
  category_id?: number;
  begin?: number;
  short?: number;
  date?: string;
  time?: string;
  home?: string;
  home_id?: number;
  away?: string;
  away_id?: number;
  children?: IPalimpsestItem[];
  tournamentName?: string;
  categoryName?: string;
  sportName?: string;
  binding?: number;
}

export interface IPalimpsestResponsePayload {
  sports: IPalimpsestItem[];
  outrights: IPalimpsestItem[];
  favourites: number[];
  widgets: number[];
}

export interface ITimeFilter {
  label: number;
  value?: number;
}

export interface IGroupConfig {
  id: number;
  label: string;
  hint: string;
  main: boolean;
  sport_id: number;
}

export interface ITournament {
  id: number;
  caller?: string;
  label: string;
  isExpanded: boolean;
  groups: IGroupData[];
  events: IEventData[];
  selectedGroup: number | string;
  selectedMarket: number | string;
  spreads: Record<string, IOddData[]>;
  selectedSpreads: any;
  players: Record<string, Record<string, IOddData[]>>;
  selectedPlayers: Record<string, Record<string, number | string>>;
  order: number;
}

export interface IGroupData {
  id: number;
  children: Event[];
  label?: string;
  markets: number[];
}

export interface IEventData {
  id: number;
  label: string;
  date: string;
  time: string;
  short: string;
  sport_id: number;
  category_id: number;
  tournament_id: number;
  markets: IMarketData[];
  tournamentLabel?: string;
  market_count?: number;
  begin?: number;
}

export interface IOddData {
  id: number;
  label: string;
  code: string;
  quick: string;
  spread: number;
  unique: string;
  value: number;
  extra: string;
  locked: boolean;
}

export interface IOddConfig {
  id: number;
  label: string;
  hint: string;
  quick: string;
  value: number;
  extra?: IOddData;
  unique: string;
}

export interface IMarketConfig {
  id: number;
  label: string;
  hint: string;
  sport_id: number;
  group_id: number;
  spread: boolean;
  player: boolean;
  main: boolean;
  odds: IOddConfig[];
  spread_default: string;
  spread_type: string;
}

export interface ISportWidgetRequest {
  action: string;
  data: {
    widget: string;
    sportId: number;
  };
}

export interface IMarketData {
  id: number;
  group: number;
  odds: IOddData[];
}

export interface ISelectedEvent {
  id: number;
  selectedGroup: string | number;
  tournamentId: number;
}

export interface ITournamentRequestPayload {
  action: string;
  caller?: string;
  data: {
    tournamentId: number;
    groupId?: number;
    eventIdFromWidget?: number;
  };
}

export interface ITournamentResponsePayload {
  id: number;
  caller?: string;
  groupId?: number;
  events: IEventData[];
  groups: IGroupData[];
}

export interface IConfigResponsePayload {
  marketgroups: IGroupConfig[];
  markets: IMarketConfig[];
}

const initialState: SportState = {
  isFetchingPalimpsest: false,
  isFetchingTournament: false,
  isFetchingEvent: false,
  isFetchingConfig: false,
  isFetchingFeatured: false,
  isFetchingWidget: false,
  favorites: [],
  sports: undefined,
  widgets: [],
  outrights: undefined,
  filteredSportsByTime: undefined,
  timeFilter: {
    label: 50,
    value: undefined,
  },
  expandedSportsNodes: [],
  expandedOutrightsNodes: [],
  selectedNodes: [],
  selectedEvent: undefined,
  eventMarkets: undefined,
  groupsConfig: undefined,
  marketsConfig: undefined,
  tournaments: {},
  responsive: "desktop",
  tournamentOrder: 0,
  featuredWidget: undefined,

  isWidgetVisible: true,
  isSideBarVisible: true,
  isTournamentViewVisible: true,
  isEventViewVisible: false,
  sportWidget: {
    selectedMarket: "all",
  },
  oddsToPrint: [],
  featuredSelectedSpread: "0.5",
  mobileSidebarState: "sports",
};

export const sportSlice = createSlice({
  name: "sport",
  initialState,
  reducers: {
    getConfigRequested: (state: SportState) => {
      state.isFetchingConfig = true;
    },
    getConfigSucceded: (state: SportState, action: PayloadAction<string>) => {
      const { marketgroups, markets }: IConfigResponsePayload = JSON.parse(
        action.payload
      );
      state.isFetchingConfig = false;
      state.groupsConfig = marketgroups;
      state.marketsConfig = markets;
    },
    getConfigFailed: (state: SportState) => {
      state.isFetchingConfig = false;
    },
    getPalimpsestRequested: (state: SportState) => {
      state.isFetchingPalimpsest = true;
    },
    getPalimpsestSucceded: (
      state: SportState,
      action: PayloadAction<string>
    ) => {
      const {
        sports,
        outrights,
        favourites,
        widgets,
      }: IPalimpsestResponsePayload = JSON.parse(action.payload);
      state.isFetchingPalimpsest = false;
      state.sports = sports;
      state.outrights = outrights;
      state.widgets = widgets;

      let matchedTournamentsById: IPalimpsestItem[] = [];
      favourites.map((elId) => {
        const match = recursiveSearchByType(elId, sports, "tournament", "t");
        match !== null &&
          (matchedTournamentsById = [...matchedTournamentsById, match]);
      });
      state.favorites = matchedTournamentsById;
      state.filteredSportsByTime =
        state.timeFilter && state.timeFilter.value
          ? sports
              .map((copy: IPalimpsestItem) => copy)
              .filter(
                (item) =>
                  state.timeFilter &&
                  filterTreeData(item, state.timeFilter.value)
              )
          : sports;
    },
    getPalimpsestFailed: (state: SportState) => {
      state.isFetchingPalimpsest = false;
    },
    saveTimeFilter: (state: SportState, action: PayloadAction<number>) => {
      switch (action.payload) {
        case 10:
          {
            const value = Math.round(
              (new Date().getTime() + 1 * 60 * 60 * 1000) / 1000
            );
            state.timeFilter = {
              label: 10,
              value: value,
            };
            state.sports &&
              (state.filteredSportsByTime = recursiveFunction(
                state.sports,
                value
              ));
          }
          break;
        case 20:
          {
            const value = Math.round(
              (new Date().getTime() + 3 * 60 * 60 * 1000) / 1000
            );
            state.timeFilter = {
              label: 20,
              value: value,
            };
            state.sports &&
              (state.filteredSportsByTime = recursiveFunction(
                state.sports,
                value
              ));
          }
          break;
        case 30:
          {
            const now = new Date().getTime();
            const endOfTheDay = endOfDay(new Date()).getTime();
            const timeleft = endOfTheDay - now;
            const value = Math.round((new Date().getTime() + timeleft) / 1000);
            state.timeFilter = {
              label: 30,
              value: value,
            };
            state.sports &&
              (state.filteredSportsByTime = recursiveFunction(
                state.sports,
                value
              ));
          }
          break;
        case 40:
          {
            const value = Math.round(
              (new Date().getTime() + 48 * 60 * 60 * 1000) / 1000
            );
            state.timeFilter = {
              label: 40,
              value: value,
            };
            state.sports &&
              (state.filteredSportsByTime = recursiveFunction(
                state.sports,
                value
              ));
          }
          break;
        case 50:
          state.timeFilter = {
            label: 50,
            value: undefined,
          };
          state.filteredSportsByTime = state.sports;
          break;
        default:
          state.timeFilter = {
            label: 50,
            value: undefined,
          };
      }
    },
    expandSportsNode: (state: SportState, action: PayloadAction<string[]>) => {
      state.expandedSportsNodes = action.payload;
    },
    expandOutrightsNode: (
      state: SportState,
      action: PayloadAction<string[]>
    ) => {
      state.expandedOutrightsNodes = action.payload;
    },
    selectNode: (state: SportState, action: PayloadAction<string>) => {
      if (state.selectedNodes.includes(action.payload)) {
        state.selectedNodes = state.selectedNodes.filter(
          (el) => el !== action.payload
        );
        const tournament = parseInt(action.payload.split("|", 3)[2]);
        const copy = { ...state.tournaments };
        delete copy[tournament];
        state.tournaments = copy;
        const columnsNr = process.env.REACT_APP_SPORT_CONTENT_COLUMNS as string;
        if (columnsNr !== "3") {
          /**on node deselect,on desktop, save successor event as selected only in 4col */
          if (state.responsive === "desktop") {
            const tournamentsItems = Object.keys(state.tournaments);
            const tournamentsLength = tournamentsItems.length;
            tournamentsLength === 0 && (state.isWidgetVisible = true);
            const nextKey = tournamentsLength > 0 && tournamentsItems[0];
            nextKey
              ? (state.selectedEvent = {
                  id: copy[nextKey].events[0].id,
                  selectedGroup: "All",
                  tournamentId: tournament,
                })
              : (state.selectedEvent = undefined);
            !nextKey && (state.eventMarkets = undefined);
          }
        }
      } else {
        state.selectedNodes = [action.payload, ...state.selectedNodes];
      }
    },
    getTournamentRequested: (
      state: SportState,
      action: PayloadAction<ITournamentRequestPayload>
    ) => {
      const { data } = action.payload;
      const { tournamentId, eventIdFromWidget } = data;
      state.isFetchingTournament = true;
      /*save first event by default only for desktop*/
      if (state.responsive === "desktop") {
        if (state.isWidgetVisible) {
          state.isWidgetVisible = false;
          state.sportWidget = {
            selectedMarket: "all",
          };
        }
        const tournament =
          recursiveSearchByType(
            tournamentId,
            state.sports,
            "tournament",
            "t"
          ) ||
          recursiveSearchByType(
            tournamentId,
            state.outrights,
            "tournament",
            "t"
          );
        const events = tournament !== null && tournament.children;
        const eventId = events && events[0].id;
        const columnsNr = process.env.REACT_APP_SPORT_CONTENT_COLUMNS as string;
        if (columnsNr !== "3") {
          if (eventIdFromWidget) {
            state.selectedEvent = {
              id: eventIdFromWidget,
              selectedGroup: "All",
              tournamentId: tournamentId,
            };
          } else {
            eventId &&
              action.payload.caller !== "outrights" &&
              action.payload.action !== "update-groups-events" &&
              action.payload.action !== "new-search" &&
              (state.selectedEvent = {
                ...state.selectedEvent,
                id: eventId,
                selectedGroup: "All",
                tournamentId: tournamentId,
              });
          }
        }
      }
    },
    getTournamentSucceded: (
      state: SportState,
      action: PayloadAction<string>
    ) => {
      const {
        id,
        caller,
        groupId,
        events,
        groups,
      }: ITournamentResponsePayload = JSON.parse(action.payload);
      state.isFetchingTournament = false;
      state.tournamentOrder += 1;
      /** calculate spreads and players */
      const spreadsOrPlayersData = calculateSpreadsOrPlayers(
        events,
        state.groupsConfig?.find((el) => el.id === groupId)?.main
          ? state.responsive === "desktop"
            ? "all"
            : groups.find((gr) => gr.id === groupId)?.markets[0]
          : groups[0]["markets"][0],
        state.marketsConfig,
        groupId
      );
      /******************************** */
      if (
        state.responsive === "desktop" &&
        process.env.REACT_APP_SPORT_CONTENT_COLUMNS !== "3" &&
        caller === "outrights"
      ) {
        /**for outrights save selected event after getting tournament data, and reordering for odds */
        state.selectedEvent = {
          id: events.sort(
            (a, b) => a.markets[0].odds[0].value - b.markets[0].odds[0].value
          )[0].id,
          selectedGroup: "All",
          tournamentId: id,
        };
      }

      state.tournaments = {
        ...state.tournaments,
        [id]: {
          id: id,
          caller: caller,
          isExpanded: true,
          selectedGroup: groupId,
          groups: groups,
          events: events,
          selectedMarket: state.groupsConfig?.find((el) => el.id === groupId)
            ?.main
            ? state.responsive === "desktop"
              ? "all"
              : groups.find((gr) => gr.id === groupId)?.markets[0]
            : groups[0]["markets"][0],
          spreads: spreadsOrPlayersData && spreadsOrPlayersData["spreads"],
          selectedSpreads:
            spreadsOrPlayersData && spreadsOrPlayersData["selectedSpreads"],
          players: spreadsOrPlayersData && spreadsOrPlayersData["players"],
          selectedPlayers:
            spreadsOrPlayersData && spreadsOrPlayersData["selectedPlayers"],
          order: state.tournamentOrder,
        },
      };
    },
    getTournamentFailed: (state: SportState) => {
      state.isFetchingTournament = false;
    },
    updateTournament: (state: SportState, action: PayloadAction<string>) => {
      const { id, data, marketId, eventId, isGeneralSpread }: any = JSON.parse(
        action.payload
      );

      Object.keys(data).map((key) => {
        switch (key) {
          case "events":
            {
              /** calculate spreads and players */
              const spreadsOrPlayersData = calculateSpreadsOrPlayers(
                data["events"],
                state.tournaments[id]["selectedMarket"],
                state.marketsConfig,
                state.tournaments[id]["selectedGroup"]
              );
              /******************************** */
              state.tournaments = {
                ...state.tournaments,
                [id]: {
                  ...state.tournaments[id],
                  events: data["events"],
                  spreads: spreadsOrPlayersData["spreads"],
                  selectedSpreads: isObjectEmpty(
                    state.tournaments[id]["selectedSpreads"]
                  )
                    ? spreadsOrPlayersData["selectedSpreads"]
                    : state.tournaments[id]["selectedSpreads"],
                  players: spreadsOrPlayersData["players"],
                  selectedPlayers: isObjectEmpty(
                    state.tournaments[id]["selectedPlayers"]
                  )
                    ? spreadsOrPlayersData["selectedPlayers"]
                    : state.tournaments[id]["selectedPlayers"],
                },
              };
              state.isFetchingTournament = false;
            }
            break;
          case "selectedMarket":
            {
              /** calculate spreads and players */
              const spreadsOrPlayersData = calculateSpreadsOrPlayers(
                state.tournaments[id]["events"],
                data["selectedMarket"],
                state.marketsConfig,
                state.tournaments[id]["selectedGroup"]
              );
              /******************************** */
              state.tournaments = {
                ...state.tournaments,
                [id]: {
                  ...state.tournaments[id],
                  selectedMarket: data["selectedMarket"],
                  spreads: spreadsOrPlayersData["spreads"],
                  selectedSpreads: spreadsOrPlayersData["selectedSpreads"],
                  players: spreadsOrPlayersData["players"],
                  selectedPlayers: spreadsOrPlayersData["selectedPlayers"],
                },
              };
            }
            break;
          case "selectedSpreads":
            {
              if (isGeneralSpread) {
                state.tournaments = {
                  ...state.tournaments,
                  [id]: {
                    ...state.tournaments[id],
                    [key]: {
                      ...state.tournaments[id][key],
                      [marketId]: data[key],
                      events: undefined,
                    },
                  },
                };
              } else {
                state.tournaments = {
                  ...state.tournaments,
                  [id]: {
                    ...state.tournaments[id],
                    [key]: {
                      ...state.tournaments[id][key],
                      events: {
                        ...state.tournaments[id][key]["events"],
                        [eventId]:
                          state.tournaments[id][key]["events"] &&
                          state.tournaments[id][key]["events"][eventId]
                            ? {
                                ...state.tournaments[id][key]["events"][
                                  eventId
                                ],
                                [marketId]: data[key],
                              }
                            : {
                                [marketId]: data[key],
                              },
                      },
                    },
                  },
                };
              }
            }
            break;
          case "selectedPlayers":
            state.tournaments = {
              ...state.tournaments,
              [id]: {
                ...state.tournaments[id],
                [key]: {
                  ...state.tournaments[id][key],
                  [eventId]: {
                    [marketId]: data[key],
                  },
                },
              },
            };
            break;
          default:
            state.tournaments = {
              ...state.tournaments,
              [id]: {
                ...state.tournaments[id],
                [key]: data[key],
              },
            };
            break;
        }
      });
    },
    deleteTournament: (state: SportState, action: PayloadAction<number>) => {
      const copy = { ...state.tournaments };
      delete copy[action.payload];
      state.selectedNodes = state.selectedNodes.filter(
        (el) => !el.includes(action.payload.toString())
      );
      state.tournaments = copy;
      const tournamentsItems = Object.keys(state.tournaments);
      const tournamentsLength = tournamentsItems.length;
      /**when tournament is deleted, save successor tournament's event as selected */
      if (state.responsive === "desktop") {
        tournamentsLength === 0 && (state.isWidgetVisible = true);
      } else {
        if (tournamentsLength === 0) {
          state.isSideBarVisible = true;
          state.isTournamentViewVisible = false;
        }
      }
    },
    getEventRequested: (state: SportState, action: PayloadAction<number>) => {
      state.isFetchingEvent = true;
      action;
    },
    getEventSucceded: (
      state: SportState,
      action: PayloadAction<IMarketData[]>
    ) => {
      state.isFetchingEvent = false;
      state.eventMarkets = action.payload;
    },
    getEventFailed: (state: SportState) => {
      state.isFetchingEvent = false;
    },
    updateGroupInSelectedEvent: (
      state: SportState,
      action: PayloadAction<number | string>
    ) => {
      state.selectedEvent &&
        (state.selectedEvent = {
          ...state.selectedEvent,
          selectedGroup: action.payload,
        });
    },
    saveSelectedEvent: (
      state: SportState,
      action: PayloadAction<ISelectedEvent | undefined>
    ) => {
      if (state.selectedEvent?.id === action.payload?.id) {
        state.selectedEvent = undefined;
      } else {
        state.selectedEvent = action.payload;
        !action.payload && (state.eventMarkets = undefined);
      }
    },
    getFeaturedWidgetRequested: (state: SportState) => {
      state.isFetchingFeatured = true;
    },
    getFeaturedWidgetSuccess: (
      state: SportState,
      action: PayloadAction<IEventData[]>
    ) => {
      state.isFetchingFeatured = false;
      state.featuredWidget = action.payload;
    },
    getFeaturedWidgetFailed: (state: SportState) => {
      state.isFetchingFeatured = false;
    },
    updateFeaturedWidget: (
      state: SportState,
      action: PayloadAction<string>
    ) => {
      state.featuredSelectedSpread = action.payload;
    },
    getSportWidgetRequested: (
      state: SportState,
      action: PayloadAction<ISportWidgetRequest>
    ) => {
      state.isFetchingWidget = true;
      action;
    },
    getSportWidgetSucceded: (
      state: SportState,
      action: PayloadAction<IEventData[]>
    ) => {
      state.isFetchingWidget = false;
      const spreadsOrPlayersData = calculateSpreadsOrPlayers(
        action.payload,
        "all",
        state.marketsConfig
      );
      state.sportWidget = {
        ...state.sportWidget,
        events: action.payload,
        spreads: spreadsOrPlayersData["spreads"],
        selectedSpreads: spreadsOrPlayersData["selectedSpreads"],
        players: spreadsOrPlayersData["players"],
        selectedPlayers: spreadsOrPlayersData["selectedPlayers"],
      };
    },
    getSportWidgetFailed: (state: SportState) => {
      state.isFetchingWidget = false;
    },
    updateSportWidget: (state: SportState, action: PayloadAction<any>) => {
      state.isFetchingWidget = false;
      const { data, marketId, eventId, isGeneralSpread } = action.payload;
      Object.keys(data).map((key) => {
        switch (key) {
          case "selectedSpreads":
            {
              if (isGeneralSpread) {
                marketId &&
                  (state.sportWidget = {
                    ...state.sportWidget,
                    [key]: {
                      ...state.sportWidget[key],
                      [marketId]: data[key],
                      events: undefined,
                    },
                  });
              } else {
                marketId &&
                  (state.sportWidget = {
                    ...state.sportWidget,
                    [key]: {
                      ...state.sportWidget[key],
                      events: {
                        ...state.sportWidget[key]["events"],
                        [eventId]:
                          state.sportWidget[key]["events"] &&
                          state.sportWidget[key]["events"][eventId]
                            ? {
                                ...state.sportWidget[key]["events"][eventId],
                                [marketId]: data[key],
                              }
                            : {
                                [marketId]: data[key],
                              },
                      },
                    },
                  });
              }
            }
            break;
          case "selectedPlayers":
            state.sportWidget = {
              ...state.sportWidget,
              [key]: {
                ...state.sportWidget[key],
                [eventId]: {
                  [marketId]: data[key],
                },
              },
            };
            break;
          default:
            state.sportWidget = {
              ...state.sportWidget,
              [key]: data[key],
            };
            break;
        }
      });
    },
    toggleWidgetVisible: (
      state: SportState,
      action: PayloadAction<boolean>
    ) => {
      state.isWidgetVisible = action.payload;
    },
    toggleSidebarVisible: (
      state: SportState,
      action: PayloadAction<boolean>
    ) => {
      state.isSideBarVisible = action.payload;
    },
    toggleTournamentViewVisible: (
      state: SportState,
      action: PayloadAction<boolean>
    ) => {
      state.isTournamentViewVisible = action.payload;
    },
    toggleEventViewVisible: (
      state: SportState,
      action: PayloadAction<boolean>
    ) => {
      state.isEventViewVisible = action.payload;
    },
    toggleAppState: (state: SportState, action: PayloadAction<string>) => {
      if (action.payload === "desktop") {
        state.responsive = "desktop";
        if (state.selectedEvent) {
          state.isWidgetVisible = false;
        } else {
          state.isWidgetVisible = true;
        }
        state.isSideBarVisible = true;
        state.isTournamentViewVisible = true;
        state.isEventViewVisible = true;
      } else {
        state.responsive = "mobile-tablet";
        if (!state.selectedEvent) {
          state.isWidgetVisible = false;
          state.isSideBarVisible = true;
          state.isTournamentViewVisible = false;
          state.isEventViewVisible = false;
        } else {
          state.isWidgetVisible = false;
          state.isSideBarVisible = false;
          state.isTournamentViewVisible = false;
          state.isEventViewVisible = true;
        }
      }
    },
    showSidebar: (state: SportState) => {
      state.isSideBarVisible = true;
      state.isTournamentViewVisible = false;
    },
    hideSidebar: (state: SportState) => {
      state.isSideBarVisible = false;
      state.isTournamentViewVisible = true;
      state.isWidgetVisible = false;
    },
    showTournamentsview: (state: SportState) => {
      state.isTournamentViewVisible = true;
      state.isEventViewVisible = false;
    },
    hideTournamentsview: (state: SportState) => {
      state.isTournamentViewVisible = false;
      state.isEventViewVisible = true;
    },
    showEventview: (state: SportState) => {
      state.isEventViewVisible = true;
      state.isSideBarVisible = false;
    },
    hideEventview: (state: SportState) => {
      state.isEventViewVisible = false;
      Object.keys(state.tournaments).length > 0
        ? (state.isTournamentViewVisible = true)
        : (state.isSideBarVisible = true);
      state.selectedEvent = undefined;
    },
    handleSideBarDesktop: (
      state: SportState,
      action: PayloadAction<boolean>
    ) => {
      state.isSideBarVisible = action.payload;
    },
    setMobileSidebarState: (
      state: SportState,
      action: PayloadAction<string>
    ) => {
      state.mobileSidebarState = action.payload;
    },
    clearOddsToPrint: (state: SportState) => {
      state.oddsToPrint = [];
    },
    clearSelectedNodes: (state: SportState) => {
      state.selectedNodes = [];
      state.tournaments = {};
    },
    clearSelectedEvent: (state: SportState) => {
      state.selectedEvent = undefined;
    },
  },
});

export const {
  getConfigRequested,
  getConfigSucceded,
  getConfigFailed,
  getPalimpsestRequested,
  getPalimpsestSucceded,
  getPalimpsestFailed,
  saveTimeFilter,
  expandSportsNode,
  expandOutrightsNode,
  selectNode,
  getTournamentRequested,
  getTournamentSucceded,
  getTournamentFailed,
  getEventRequested,
  getEventSucceded,
  getEventFailed,
  updateGroupInSelectedEvent,
  updateTournament,
  deleteTournament,
  saveSelectedEvent,
  getFeaturedWidgetRequested,
  getFeaturedWidgetSuccess,
  getFeaturedWidgetFailed,
  updateFeaturedWidget,
  getSportWidgetRequested,
  getSportWidgetSucceded,
  getSportWidgetFailed,
  updateSportWidget,
  toggleWidgetVisible,
  toggleTournamentViewVisible,
  toggleEventViewVisible,
  toggleSidebarVisible,
  toggleAppState,
  clearOddsToPrint,
  showSidebar,
  hideSidebar,
  showTournamentsview,
  hideTournamentsview,
  showEventview,
  handleSideBarDesktop,
  hideEventview,
  setMobileSidebarState,
  clearSelectedNodes,
  clearSelectedEvent,
} = sportSlice.actions;

export default sportSlice.reducer;
