import React, { ReactElement, useEffect, useState } from "react";
import styles from "./styles.module.scss"
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import ReactToPrint from "react-to-print";
import Receipt from "../../coupon/Receipt";
import { Translate } from "react-redux-i18n";
import FontAwesomeIcon from "../FontAwesomeIcon";
import CopyToClipboard from "react-copy-to-clipboard";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import {
  confirmCouponRequested,
  getCashoutRequested,
  PlayedCoupon,
  PlayedCouponOddContent,
  printPlayedCouponRequested,
  refuseCouponRequested,
} from "../../../redux/coupon/couponSlice";
import html2canvas from "html2canvas";
import { openDialog } from "../../../redux/dialog/dialogSlice";

export interface NewEvent {
  id: number,
  begin: number,
  label: string,
  fix: boolean,
  category: string,
  tournament: string,
  odds: PlayedCouponOddContent[] | undefined,
  sport: string,
  short: number,
  running: boolean,
  winner: boolean,
  empty?: boolean,
  columns_count?: number
}

interface IProps {
  skinLogo?: string
}

export default function CouponContent({ skinLogo }: IProps): ReactElement {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(state => state.auth.isAuth)
  const isFetchingCouponForPrint = useAppSelector((state) => state.coupon.isFetchingCouponForPrint)
  const playedCouponState = useAppSelector(state => state.coupon.playedCouponState);
  const playedCoupon = useAppSelector(state => state.coupon.playedCoupon);
  const isBooked = useAppSelector(state => state.coupon.isBooked)
  const [counter, setCounter] = useState<number>(30)
  const [composedReceiptData, setComposedReceiptData] = useState<NewEvent[] | undefined>(undefined)
  const [open, setOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  ///////////////////////////////////////////////
  const ref = React.createRef<HTMLElement>();
  ////////////////////////////////////////////////////

  const serial = isBooked ? playedCouponState?.serial : playedCoupon?.serial

  const handleCouponConfirm = (id: string) => {
    dispatch(confirmCouponRequested(id))
  }

  const handleCouponRefuse = (id: string) => {
    dispatch(refuseCouponRequested(id))
  }

  const handleShareClick = (event: any) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleShare = (state: string) => {
    if (ref.current) {
      html2canvas(ref.current).then((canvas) => {
        const dataUrl = canvas.toDataURL("image/jpeg")
        return dataUrl;
      }).then(async (response) => {
        const blob = await (await fetch(response)).blob();
        const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
        if (navigator.canShare && navigator.canShare({ files: [file] })) {
          if (state === "link") {
            navigator.share({
              url: `${window.location.origin}/sport/coupon/${playedCoupon?.serial}`
            }).then((res) => res).catch((err) => err)
          } else if (state === "file") {
            navigator.share({
              files: [file]
            }).then((res) => res).catch((err) => err)
          }
        } else {
          console.log('Web Share API not supported in this browser.');
        }
      })
    }
  }

  const handleCashoutClick = (couponId: string) => {
    dispatch(getCashoutRequested(couponId))
    dispatch(openDialog({ type: "cashout", payload: couponId }))
  }

  const composeData = (data: PlayedCoupon) => {
    let obj: NewEvent[] = []
    data.odds.map(el => {
      if (obj.find((ele: NewEvent) => ele.id === el.event.id)) {
        return
      } else {
        const newEvent = {
          begin: el.event.begin,
          id: el.event.id,
          label: el.event.label,
          fix: el.fix,
          category: el.category.label,
          tournament: el.tournament.label,
          odds: playedCoupon?.odds.filter(odd => odd.event.id === el.event.id),
          sport: el.sport.label,
          short: el.event.short,
          running: el.odd.running,
          winner: el.odd.winner,
          empty: el.odd.void,
          columns_count: el.columns_count
        }
        if (obj[el.event.id]) {
          return
        } else {
          obj = [...obj, newEvent]
        }
      }
    })
    return obj;
  }

  useEffect(() => {
    playedCoupon && setComposedReceiptData(composeData(playedCoupon))
  }, [playedCoupon])

  useEffect(() => {
    if (playedCoupon?.user_confirm) {
      const timeoutId = setTimeout(() => handleCouponRefuse(playedCoupon.id), 30000)
      return () => clearTimeout(timeoutId)
    }
  }, [playedCoupon?.user_confirm])

  useEffect(() => {
    if (playedCoupon?.user_confirm) {
      if (counter <= 0) {
        setCounter(30)
      }
      const intervalId = setInterval(() => setCounter(counter - 1), 1000)
      return () => clearInterval(intervalId)
    }
  }, [counter, playedCoupon?.user_confirm])

  return (
    <>
      {(isBooked || (playedCoupon && playedCoupon?.played)) &&
        <Box className={styles.buttonsContainer}>
          <Box className={styles.buttonGroup}>
            {(((playedCoupon?.printable && isAuth) || (isBooked)) || (!isAuth && isBooked)) &&
              <ReactToPrint
                trigger={() =>
                  <Button
                    variant="contained"
                    color="secondary"
                    className={styles.button}
                    startIcon={<FontAwesomeIcon
                      type="fal"
                      name={"print"}
                      margin={1}
                      width="15px"
                    />}
                  >
                    <Translate value="coupon.print" />
                  </Button>
                }
                content={() => ref.current}
                onBeforePrint={() => {
                  !isBooked &&
                    playedCouponState ?
                    dispatch(printPlayedCouponRequested(playedCouponState.id))
                    : !isBooked && playedCoupon && dispatch(printPlayedCouponRequested(playedCoupon.id));
                }}
                pageStyle={`@page {size: 80mm 150mm; margin: 0 !important;page-break-inside: avoid !important;}`}
              />
            }
            {/* COPY BUTTON */}
            {/* {serial ?
              <CopyToClipboard text={serial}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={styles.button}
                  startIcon={<FontAwesomeIcon
                    type="fal"
                    name={"copy"}
                    margin={1}
                    width="15px"
                  />}>
                  <Translate value="coupon.copyCode" />
                </Button>
              </CopyToClipboard>
              :
              <Button
                variant="contained"
                color="secondary"
                className={styles.button}
                startIcon={<FontAwesomeIcon
                  type="fal"
                  name={"copy"}
                  margin={1}
                  width="15px"
                />}>
                <Translate value="coupon.copyCode" />
              </Button>
            } */}

            {/* SHARE BUTTON */}
            {/* <Button
              onClick={(e) => handleShareClick(e)}
              variant="contained"
              color="secondary"
              className={`${styles.button} ${styles.shareButton}`}
              startIcon={<FontAwesomeIcon
                type="fal"
                name="arrow-up-from-square"
                margin={1}
                width="15px"
              />}
            >
              <Translate value="coupon.share" />
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={() => setOpen(false)} className={styles.menu}>
              <MenuItem
                divider
                onPointerUp={() => handleShare("file")}
                className={styles.menuItem}>
                <Box className={styles.imageIcon}>
                  <FontAwesomeIcon name="image" type="fas" />
                </Box>
                <Translate value="coupon.image" />
              </MenuItem>
            </Menu> */}
            {playedCoupon?.running && playedCoupon.cashout &&
              <Box className={styles.cashoutButtonRow}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<FontAwesomeIcon type="fas" name="money-bill" />}
                  onClick={() => handleCashoutClick(playedCoupon.id)}
                  className={styles.iconButton}
                >
                  <Translate value="coupon.cashout" />
                </Button>
              </Box>
            }
          </Box>
          {/* ORIGINAL CASHOUT BUTTOn */}
          {/* {playedCoupon?.running && playedCoupon.cashout &&
            <Box className={styles.cashoutButtonRow}>
              <Button
                startIcon={<FontAwesomeIcon type="fas" name="money-bill" />}
                onClick={() => handleCashoutClick(playedCoupon.id)}
                className={styles.iconButton}
              >
                <Translate value="coupon.cashout" />
              </Button>
            </Box>
          } */}
        </Box>
      }
      <DialogContent className={styles.dialogContent}>

        {isFetchingCouponForPrint ?
          <Box className={styles.couponUpdateContainer}>
            <CircularProgress color="secondary" />
            <Typography><Translate value="coupon.couponUpdate" /></Typography>
          </Box>
          :
          <Box ref={ref} sx={{ width: 302 }}>
            {(composedReceiptData || isBooked) &&
              <Receipt
                isBooked={isBooked}
                playedCouponState={playedCouponState}
                playedCoupon={playedCoupon}
                counter={counter}
                handleCouponRefuse={handleCouponRefuse}
                handleCouponConfirm={handleCouponConfirm}
                skinLogo={skinLogo}
                composedReceiptData={composedReceiptData}
              />
            }
          </Box>
        }
      </DialogContent>
    </>
  )
}