import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CartesianProduct } from "js-combinatorics";
import {
  calculateMultiplicationOfOddComb,
  calculateOddBonus,
  calculatePotentialWinArray,
  calculateArrayMaxMinValue,
  calculatePotentialWinValue,
  composeCouponData,
  composeCouponOdds,
  composeGrCombinations,
  calculateBettingAmount,
  updatePotentialWinsInCheckedGroups,
  calculateSystemMinWin,
  calculateSystemMaxWin,
  calculateSystemTotWin,
  calculateSystemMaxBonus,
  calculateSystemTotBonus,
  calculateSystemMinBonus,
  calculateBonusArrayPerGroup,
  updateCheckedGroupsOnOddRemove,
  calculateCouponType,
  calculateMultiplicationOfSystemOddComb,
  calculateAllCombinationsForCheckedGroups,
  calculateMultiplicationOfSystemOddCombMin,
  updateCheckedGroupsOnCombRemove,
  updateDataInCheckedGroups,
  calculateSystemGrMaxBonus,
  calculateSystemGrMinBonus,
  calculateNrOfCombinations,
} from "./coupon.utils";

export interface CouponState {
  isFetching: boolean;
  isFetchingPlayedCoupon: boolean;
  isFetchingCouponForPrint: boolean;
  isFetchingCancelCoupon: boolean;
  isFetchingPayedCoupon: boolean;
  isFetchingOddsUpdate: boolean;
  isPlayedCouponReceiptVisible: boolean;
  isPlayedCouponDetailVisible: boolean;
  isPotentialWinOverLimit: boolean;
  isFetchingCashout: boolean;
  bettingConfig?: BettingConfig;
  couponTab: number;
  couponType?: string;
  couponTypeTab: number;
  coupon: Record<string, ICouponOdd[]>;
  odds: ICouponOdd[];
  couponLength: number;
  combination?: number;
  combinationAmount: string;
  bettingAmount: string;
  maxOdd?: number;
  minOdd?: number;
  oddBonus?: number[];
  maxBonus?: number;
  minBonus?: number;
  potentialWinArray?: number[];
  potentialWinValue?: number;
  maxPotentialWin?: number;
  minPotentialWin?: number;
  fixedArr: PlayedCouponFixed[];
  groupComb: Record<string, GroupComb>;
  checkedGroups: Record<string, CheckedGroup>;
  isBooked: boolean;
  isPlayed: boolean;
  proceedState: boolean;
  bookState: boolean;
  playedCoupon?: PlayedCoupon;
  playedCouponState?: PlayedCouponState;
  playedCouponsStates?: Record<string, PlayedCouponState>;
  playedBets?: MyBet[];
  inAcceptanceBets?: MyBet[];
  quickbetOdds?: any;
  couponRequestPayload?: CouponRequestPayload;
  backdropVisibility: boolean;
  couponMobile?: boolean;
  betCodesData: Record<string, string>[];
  couponId: string;
  payedCouponId: string;
  isCouponMobileVisible: boolean;
  closedEventsMsg?: string;
  combinationFilter: string;
  cashout?: CashoutData;
  cashoutState?: string;
}
export interface BettingConfig {
  bonus: Record<string, number>;
  deafult_agency: Record<string, number>;
  setting: Record<string, number>;
}
export interface BettingConfigSetting {
  rank_bonus: number;
}
export interface MyBet {
  id: string;
  wallet_id: number;
  serial: string;
  acceptance: boolean;
  played: boolean;
  replayed: boolean;
  running: boolean;
  winner: boolean;
  user_confirm: boolean;
  coupon_state_id: string;
  coupon_state: CouponStateType;
  cancellable: boolean;
  cashout: boolean;
  created_at: string;
  replay: boolean;
  void: boolean;
}
export interface ICouponOdd {
  id?: number;
  value?: number;
  unique?: string;
  code?: string;
  locked: boolean;
  oddLabel?: string;
  eventId?: number;
  eventLabel?: string;
  marketId?: number;
  marketLabel?: string;
  tournamentId?: number;
  spreadLabel?: any;
  fix?: boolean;
  caller?: string;
}
export interface GroupComb {
  nrComb: number;
}
export interface CheckedGroup {
  minOdd: number;
  maxOdd: number;
  combination: number;
  combinationAmount: string;
  bettingAmount: string;
  minBonus: number;
  maxBonus: number;
  minPotentialWin: number;
  maxPotentialWin: number;
}

export interface ProvigionalPlan {
  id: number;
  name: string;
  label: string;
}
export interface Currency {
  id: number;
  divider: number;
  decimal: number;
  symbol: string;
  label: string;
}

export interface CombinationOdd {
  id?: number;
  eventId?: number;
  marketId?: number;
  oddLabel?: string;
  spreadLabel?: string;
  marketLabel?: string;
  unique?: string;
  value?: number;
  code?: string;
  fix?: boolean;
  eventLabel?: string;
  locked?: boolean;
  tournamentId?: number;
  running?: boolean;
  void?: boolean;
  winner?: boolean;
}

export interface SystemCombination {
  active: boolean;
  caller: string;
  category: PlayedCouponOddCategory;
  code: string;
  event: PlayedCouponOddEvent;
  fix: boolean;
  locked: boolean;
  market: PlayedCouponOddMarket;
  odd: PlayedCouponOdd;
  sport: PlayedCouponOddSport;
  tournament: PlayedCouponOddTournament;
  unique: string;
  value: number;
}

export interface PlayedCoupon {
  id: string;
  wallet_id: number;
  username: string;
  parent_id: number;
  bet_amount: number;
  bet_changed: boolean;
  bet_required: number;
  bonus_max: number;
  bonus_min: number;
  columns_count: number;
  coupon_state_id: string;
  coupon_state: CouponStateType;
  coupon_type: PlayedCouponType;
  cancellable: boolean;
  cancelled: boolean;
  cashout: boolean;
  created_at: string;
  closed_at: string;
  events_count: string;
  fixed: PlayedCouponFixed[];
  max_potential_win: number;
  min_potential_win: number;
  odds: PlayedCouponOddContent[];
  odds_max: number;
  odds_min: number;
  systems: PlayedCouponSystems[];
  win_amount: number;
  win_max: number;
  serial: string;
  printed: boolean;
  printable: boolean;
  acceptance: boolean;
  played: boolean;
  user_confirm: boolean;
  replay: boolean;
  isVerifyAction?: boolean;
  winner?: boolean;
  payed?: boolean;
  payed_at?: string;
  caller?: string;
  currency: PlayedCouponCurrency;
  running: boolean;
  wincashout: boolean;
}

export interface PlayedCouponCurrency {
  id: number;
  label: string;
  decimal: number;
  divider: number;
  symbol: string;
  enabled: boolean;
}
export interface PlayedCouponResponse {
  data: PlayedCoupon;
  isRepeated: boolean;
  divider?: number;
}
export interface PlayedCouponState {
  id: string;
  wallet_id: number;
  serial: string;
  acceptance: boolean;
  played: boolean;
  replayed: boolean;
  running: boolean;
  winner: boolean;
  user_confirm: boolean;
  coupon_state_id: string;
  cancellable: boolean;
  cashout: boolean;
  coupon_state: CouponStateType;
  created_at: string;
  replay: boolean;
}
export interface CouponStateType {
  id: string;
  label: string;
  name: string;
}
export interface PlayedCouponType {
  id: number;
  name: string;
  label: string;
}
export interface PlayedCouponFixed {
  code: string;
  fix: boolean;
  unique: string;
}
export interface PlayedCouponSystems {
  group: number;
  amount: number;
  bet_amount: number;
  comb: number;
  odds_min: number;
  odds_max: number;
  win_min: number;
  win_max: number;
}
export interface PlayedCouponOddContent {
  active: boolean;
  caller: string;
  category: PlayedCouponOddCategory;
  code: string;
  event: PlayedCouponOddEvent;
  fix: boolean;
  locked: boolean;
  market: PlayedCouponOddMarket;
  odd: PlayedCouponOdd;
  sport: PlayedCouponOddSport;
  tournament: PlayedCouponOddTournament;
  unique: string;
  value: number;
  columns_count?: number;
  extra?: number | string;
}
export interface PlayedCouponOdd {
  id: number;
  fix: boolean;
  label: string;
  locked: boolean;
  spread: string;
  state: string;
  value: number;
  value_updated: number;
  value_changed: boolean;
  value_required: number;
  running: boolean;
  winner: boolean;
  void?: boolean;
  extra: string;
}
export interface PlayedCouponOddCategory {
  id: number;
  label: string;
}
export interface PlayedCouponOddEvent {
  begin: number;
  date: string;
  id: number;
  result: string;
  label: string;
  time: string;
  short: number;
}
export interface PlayedCouponOddMarket {
  id: number;
  label: string;
}
export interface PlayedCouponOddSport {
  id: number;
  label: string;
}
export interface PlayedCouponOddTournament {
  id: number;
  label: string;
}
export interface CouponRequestPayload {
  odds?: ICouponOdd[];
  bet_amount: number;
  allow_change: number;
  allow_transfer: boolean;
  systems?: PlayedCouponSystems[];
  wallet_id?: number;
  fixed?: PlayedCouponFixed[];
  ts?: number;
}
export interface NewOddPayload {
  eventId?: number | string;
  odd: ICouponOdd;
}
export interface NewFixedOddPayload {
  fix: boolean;
  unique: string;
  code: string;
}
export interface NewGroupPayload {
  group: number;
  nrComb: number;
}
export interface NewCombAmountPayload {
  type?: string;
  data: NewCombAmountPayloadData;
}
export interface NewCombAmountPayloadData {
  group?: number;
  combinationAmount: string;
  nrComb?: number;
}
export interface IRemoveItemPayload {
  eventId?: number | string;
  unique?: string;
  value?: number;
}
export interface MyBetsResponse {
  played: MyBet[];
  acceptance: MyBet[];
}

export interface CashoutData {
  cashout: string;
  win: string;
  message: string;
  status: boolean;
}

const initialState: CouponState = {
  isFetching: false,
  isFetchingPlayedCoupon: false,
  isFetchingCouponForPrint: false,
  isFetchingCancelCoupon: false,
  isFetchingPayedCoupon: false,
  isFetchingOddsUpdate: false,
  isPlayedCouponReceiptVisible: false,
  isPlayedCouponDetailVisible: false,
  isPotentialWinOverLimit: false,
  isFetchingCashout: false,
  bettingConfig: undefined,
  couponTab: 0,
  couponTypeTab: 0,
  couponType: undefined,
  coupon: {},
  odds: [],
  couponLength: 0,
  combination: undefined,
  combinationAmount: "",
  bettingAmount: "",
  maxOdd: undefined,
  minOdd: undefined,
  oddBonus: undefined,
  maxBonus: undefined,
  minBonus: undefined,
  potentialWinArray: undefined,
  potentialWinValue: undefined,
  maxPotentialWin: undefined,
  minPotentialWin: undefined,
  fixedArr: [],
  groupComb: {},
  checkedGroups: {},
  isBooked: false,
  isPlayed: false,
  proceedState: true,
  bookState: true,
  playedCoupon: undefined,
  playedCouponsStates: undefined,
  playedBets: undefined,
  inAcceptanceBets: undefined,
  quickbetOdds: undefined,
  couponRequestPayload: undefined,
  backdropVisibility: false,
  couponMobile: false,
  betCodesData: [],
  couponId: "",
  payedCouponId: "",
  isCouponMobileVisible: false,
  combinationFilter: "all",
  cashout: undefined,
  cashoutState: undefined,
};

export const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    getBettingConfigRequested: (state: CouponState) => {
      state.isFetching = true;
    },
    getBettingConfigSucceded: (
      state: CouponState,
      action: PayloadAction<BettingConfig>
    ) => {
      state.isFetching = false;
      state.bettingConfig = action.payload;

      /** ********* find coupon type *****************************/
      state.couponType = calculateCouponType(
        state.odds,
        state.couponLength,
        Object.keys(state.coupon).length,
        state.couponTypeTab
      );
      /** *********************************************************/

      if (state.couponTypeTab === 1) {
        const updatedCheckedGroups = updatePotentialWinsInCheckedGroups(
          updateCheckedGroupsOnCombRemove(
            state.checkedGroups,
            state.groupComb,
            action.payload.setting?.max_col_system
          ),
          state.groupComb,
          state.odds,
          state.fixedArr
        );
        state.checkedGroups = updatedCheckedGroups;
        const checkedGroupsArray = Object.values(updatedCheckedGroups);
        const maxBonusValue = calculateSystemMaxBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        const maxTotBonusValue = calculateSystemTotBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        const maxWinValue = calculateSystemMaxWin(checkedGroupsArray);
        const maxTotWinValue = calculateSystemTotWin(checkedGroupsArray);
        const maxPotentialWinTotal = maxWinValue + maxBonusValue;
        const potentialWinValueTotal = maxTotBonusValue + maxTotWinValue;

        state.minBonus = calculateSystemMinBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        state.maxBonus = maxBonusValue;
        state.minPotentialWin = calculateSystemMinWin(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        state.bettingConfig &&
          (state.maxPotentialWin =
            maxPotentialWinTotal > state.bettingConfig.setting[state.couponType]
              ? state.bettingConfig.setting[state.couponType]
              : maxPotentialWinTotal);

        state.bettingConfig &&
          (state.potentialWinValue =
            potentialWinValueTotal >
            state.bettingConfig.setting[state.couponType]
              ? state.bettingConfig.setting[state.couponType]
              : potentialWinValueTotal);
        state.isPotentialWinOverLimit =
          potentialWinValueTotal > state.bettingConfig.setting[state.couponType]
            ? true
            : false;
      } else {
        const { isPotentialWinOverLimit, value } =
          (state.potentialWinArray &&
            calculatePotentialWinValue(
              state.potentialWinArray,
              state.bettingConfig?.setting[state.couponType]
            )) ||
          {};

        state.potentialWinValue = value;
        state.isPotentialWinOverLimit = isPotentialWinOverLimit
          ? isPotentialWinOverLimit
          : false;
      }
    },
    getBettingConfigFailed: (state: CouponState) => {
      state.isFetching = false;
    },
    getAuthBettingConfigRequested: (
      state: CouponState,
      action: PayloadAction<number>
    ) => {
      state.isFetching = true;
    },
    getAuthBettingConfigSucceded: (
      state: CouponState,
      action: PayloadAction<BettingConfig>
    ) => {
      state.isFetching = false;
      state.bettingConfig = action.payload;

      /** ********* find coupon type *****************************/
      state.couponType = calculateCouponType(
        state.odds,
        state.couponLength,
        Object.keys(state.coupon).length,
        state.couponTypeTab
      );
      /** *********************************************************/

      if (state.couponTypeTab === 1) {
        const updatedCheckedGroups = updatePotentialWinsInCheckedGroups(
          updateCheckedGroupsOnCombRemove(
            state.checkedGroups,
            state.groupComb,
            action.payload.setting?.max_col_system
          ),
          state.groupComb,
          state.odds,
          state.fixedArr
        );
        state.checkedGroups = updatedCheckedGroups;
        const checkedGroupsArray = Object.values(updatedCheckedGroups);
        const maxBonusValue = calculateSystemMaxBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        const maxTotBonusValue = calculateSystemTotBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        const maxWinValue = calculateSystemMaxWin(checkedGroupsArray);
        const maxTotWinValue = calculateSystemTotWin(checkedGroupsArray);
        const maxPotentialWinTotal = maxWinValue + maxBonusValue;
        const potentialWinValueTotal = maxTotBonusValue + maxTotWinValue;

        state.minBonus = calculateSystemMinBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        state.maxBonus = maxBonusValue;
        state.minPotentialWin = calculateSystemMinWin(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        state.bettingConfig &&
          (state.maxPotentialWin =
            maxPotentialWinTotal > state.bettingConfig.setting[state.couponType]
              ? state.bettingConfig.setting[state.couponType]
              : maxPotentialWinTotal);

        state.bettingConfig &&
          (state.potentialWinValue =
            potentialWinValueTotal >
            state.bettingConfig.setting[state.couponType]
              ? state.bettingConfig.setting[state.couponType]
              : potentialWinValueTotal);
        state.isPotentialWinOverLimit =
          potentialWinValueTotal > state.bettingConfig.setting[state.couponType]
            ? true
            : false;
      } else {
        const { isPotentialWinOverLimit, value } =
          (state.potentialWinArray &&
            calculatePotentialWinValue(
              state.potentialWinArray,
              state.bettingConfig?.setting[state.couponType]
            )) ||
          {};

        state.potentialWinValue = value;
        state.isPotentialWinOverLimit = isPotentialWinOverLimit
          ? isPotentialWinOverLimit
          : false;
      }
    },
    getAuthBettingConfigFailed: (state: CouponState) => {
      state.isFetching = false;
    },

    saveCouponTab: (state: CouponState, action: PayloadAction<number>) => {
      state.couponTab = action.payload;
    },
    saveCouponType: (state: CouponState, action: PayloadAction<string>) => {
      state.couponType = action.payload;
    },
    saveCouponTypeTabValue: (
      state: CouponState,
      action: PayloadAction<number>
    ) => {
      state.couponTypeTab = action.payload;
      /** ********* find coupon type************************************/
      state.couponType = calculateCouponType(
        state.odds,
        state.couponLength,
        Object.keys(state.coupon).length,
        state.couponTypeTab
      );
      if (action.payload === 1) {
        state.groupComb = composeGrCombinations(
          state.odds.length,
          state.fixedArr
        );
        state.maxOdd = undefined;
        state.minOdd = undefined;
        state.maxBonus = undefined;
        state.minBonus = undefined;
        state.maxPotentialWin = undefined;
        state.minPotentialWin = undefined;
        state.bettingAmount = "";
        state.combinationAmount = "";
        state.potentialWinArray = undefined;
        state.potentialWinValue = undefined;
        state.combination = undefined;
        state.oddBonus = undefined;
        state.isPotentialWinOverLimit = false;
      } else {
        //azzerare per system
        state.groupComb = {};
        state.checkedGroups = {};
        state.fixedArr = [];
        state.isPotentialWinOverLimit = false;

        //ricalcolare per il resto

        /** ********* find cartesian product of all odds ***********/
        const cartesianProductOfOdds = [
          ...new CartesianProduct(...Object.values(state.coupon)),
        ];
        const prodOfOddCombinations = calculateMultiplicationOfOddComb(
          cartesianProductOfOdds
        );
        state.combination = cartesianProductOfOdds.length;
        const roundedAmount = (Number(state.bettingAmount) / state.combination)
          .toFixed(4)
          .toString();
        state.combinationAmount = roundedAmount;
        const calculatedMaxOdd = calculateArrayMaxMinValue(
          "max",
          prodOfOddCombinations
        );
        const calculatedMinOdd = calculateArrayMaxMinValue(
          "min",
          prodOfOddCombinations
        );
        state.maxOdd = calculatedMaxOdd;
        state.minOdd = calculatedMinOdd;
        const calculatedOddBonus = calculateOddBonus(
          cartesianProductOfOdds,
          state.bettingConfig?.bonus,
          state.bettingConfig?.setting?.rank_bonus
        );
        state.oddBonus = calculatedOddBonus;
        state.maxBonus =
          (calculateArrayMaxMinValue("max", state.oddBonus) *
            calculatedMaxOdd *
            Number(roundedAmount)) /
          100;
        state.minBonus =
          (calculateArrayMaxMinValue("min", state.oddBonus) *
            calculatedMinOdd *
            Number(roundedAmount)) /
          100;
        const calculatedPotWinArray = calculatePotentialWinArray(
          Number(roundedAmount),
          prodOfOddCombinations,
          calculatedOddBonus
        );
        state.potentialWinArray = calculatedPotWinArray;
        state.maxPotentialWin = calculateArrayMaxMinValue(
          "max",
          calculatedPotWinArray
        );
        state.minPotentialWin = calculateArrayMaxMinValue(
          "min",
          calculatedPotWinArray
        );

        const { isPotentialWinOverLimit, value } = calculatePotentialWinValue(
          state.potentialWinArray,
          state.bettingConfig?.setting[state.couponType]
        );
        state.potentialWinValue = value;
        state.isPotentialWinOverLimit = isPotentialWinOverLimit;
      }
    },

    addOddInCoupon: (
      state: CouponState,
      action: PayloadAction<NewOddPayload>
    ) => {
      const { eventId, odd } = action.payload;
      /**if max number of comb in integral is exceeded do nothing  */
      if (
        state.couponTypeTab !== 1 &&
        state.couponLength !== Object.keys(state.coupon).length &&
        state.bettingConfig &&
        state.combination &&
        state.combination >= state.bettingConfig?.setting.max_col_integral
      ) {
        return;
      }
      /******************************************************** */
      state.couponLength += 1;
      state.odds = [...state.odds, odd];
      eventId &&
        (state.coupon = {
          ...state.coupon,
          [eventId]: state.coupon[eventId]
            ? [...[odd], ...state.coupon[eventId]]
            : [odd],
        });

      state.couponTab !== 0 && (state.couponTab = 0);

      /** ********* find coupon type************************************/
      state.couponType = calculateCouponType(
        state.odds,
        state.couponLength,
        Object.keys(state.coupon).length,
        state.couponTypeTab
      );
      /** ***********************************************************/
      if (state.couponTypeTab === 1) {
        /*************  system object calculations******************/
        const grCombinations = composeGrCombinations(
          state.odds.length,
          state.fixedArr
        );
        state.groupComb = grCombinations;
        /**************************************************************/
        const updatedCheckedGroups = updateDataInCheckedGroups(
          updateCheckedGroupsOnCombRemove(
            state.checkedGroups,
            grCombinations,
            state.bettingConfig?.setting?.max_col_system
          ),
          state.bettingAmount,
          state.odds,
          state.fixedArr,
          state.bettingConfig?.bonus,
          state.bettingConfig?.setting?.rank_bonus,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        state.checkedGroups = updatedCheckedGroups;
        const checkedGroupsArray = Object.values(updatedCheckedGroups);

        const maxBonusValue = calculateSystemMaxBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        const maxTotBonusValue = calculateSystemTotBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        const maxWinValue = calculateSystemMaxWin(checkedGroupsArray);
        const maxTotWinValue = calculateSystemTotWin(checkedGroupsArray);
        const maxPotentialWinTotal = maxWinValue + maxBonusValue;
        const potentialWinValueTotal = maxTotBonusValue + maxTotWinValue;

        state.minBonus = calculateSystemMinBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        state.maxBonus = maxBonusValue;
        state.minPotentialWin = calculateSystemMinWin(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        state.bettingConfig &&
          (state.maxPotentialWin =
            maxPotentialWinTotal > state.bettingConfig.setting[state.couponType]
              ? state.bettingConfig.setting[state.couponType]
              : maxPotentialWinTotal);
        state.bettingConfig &&
          (state.potentialWinValue =
            potentialWinValueTotal >
            state.bettingConfig.setting[state.couponType]
              ? state.bettingConfig.setting[state.couponType]
              : potentialWinValueTotal);
        state.isPotentialWinOverLimit =
          state.bettingConfig &&
          potentialWinValueTotal > state.bettingConfig.setting[state.couponType]
            ? true
            : false;
        /********************************************************************* */
      } else {
        state.combination = calculateNrOfCombinations(state.coupon);
        const roundedAmount = (Number(state.bettingAmount) / state.combination)
          .toFixed(4)
          .toString();
        state.combinationAmount = roundedAmount;

        /**  for integral calculate all odd combinations
         * only if the nr of comb is lower than the limit
         */
        const cartesianProductOfOdds = [
          ...new CartesianProduct(...Object.values(state.coupon)),
        ];
        const prodOfOddCombinations = calculateMultiplicationOfOddComb(
          cartesianProductOfOdds
        );

        /** ********* create arryay of the product of all odd combinations ******/
        const calculatedMaxOdd = calculateArrayMaxMinValue(
          "max",
          prodOfOddCombinations
        );
        const calculatedMinOdd = calculateArrayMaxMinValue(
          "min",
          prodOfOddCombinations
        );
        state.maxOdd = calculatedMaxOdd;
        state.minOdd = calculatedMinOdd;
        const calculatedOddBonus = calculateOddBonus(
          cartesianProductOfOdds,
          state.bettingConfig?.bonus,
          state.bettingConfig?.setting?.rank_bonus
        );
        state.oddBonus = calculatedOddBonus;
        state.maxBonus =
          (calculateArrayMaxMinValue("max", state.oddBonus) *
            calculatedMaxOdd *
            Number(state.combinationAmount)) /
          100;
        state.minBonus =
          (calculateArrayMaxMinValue("min", state.oddBonus) *
            calculatedMinOdd *
            Number(state.combinationAmount)) /
          100;
        const calculatedPotWinArray = calculatePotentialWinArray(
          Number(roundedAmount),
          prodOfOddCombinations,
          calculatedOddBonus
        );
        state.potentialWinArray = calculatedPotWinArray;
        state.maxPotentialWin = calculateArrayMaxMinValue(
          "max",
          calculatedPotWinArray
        );
        state.minPotentialWin = calculateArrayMaxMinValue(
          "min",
          calculatedPotWinArray
        );
        const { isPotentialWinOverLimit, value } = calculatePotentialWinValue(
          calculatedPotWinArray,
          state.bettingConfig?.setting[state.couponType]
        );
        state.potentialWinValue = value;
        state.isPotentialWinOverLimit = isPotentialWinOverLimit;
      }
    },
    getOddsUpdatesRequested: (
      state: CouponState,
      action: PayloadAction<string>
    ) => {
      state.isFetchingOddsUpdate = true;
      action;
    },
    getOddsUpdatesSucceded: (
      state: CouponState,
      action: PayloadAction<any>
    ) => {
      /** if there are no odds clear coupon */
      if (action.payload.length === 0) {
        state.odds = [];
        state.coupon = {};
        state.couponLength = 0;
        state.couponType = undefined;
        state.maxOdd = undefined;
        state.minOdd = undefined;
        state.maxBonus = undefined;
        state.minBonus = undefined;
        state.maxPotentialWin = undefined;
        state.minPotentialWin = undefined;
        state.bettingAmount = "";
        state.combinationAmount = "";
        state.potentialWinValue = undefined;
        state.combination = undefined;
        state.potentialWinArray = undefined;
        state.oddBonus = undefined;
        state.couponType = undefined;
        state.fixedArr = [];
        state.checkedGroups = {};
        state.groupComb = {};
        state.couponTypeTab = 0;
        state.isPotentialWinOverLimit = false;
      } else {
        let oddsCopy = [...state.odds];
        const oddsByEventCopy = { ...state.coupon };
        oddsCopy.map((el) => {
          const match = action.payload.find(
            (it: any) => it.unique == el.unique
          );
          if (match) {
            const indexInOdds = oddsCopy.findIndex(
              (item) => item.unique === match.unique
            );
            if (indexInOdds !== -1) {
              if (
                state.odds[indexInOdds].value !== match.value ||
                state.odds[indexInOdds].locked !== match.locked
              ) {
                oddsCopy[indexInOdds] = {
                  ...state.odds[indexInOdds],
                  code: match.code,
                  value: match.value,
                  locked: match.locked,
                };
              }
            }
          } else {
            oddsCopy = oddsCopy.filter((ar) => ar.unique !== el.unique);
          }
        });
        Object.values(oddsByEventCopy).map((value) => {
          value.map((el) => {
            const match = action.payload.find(
              (it: any) => it.unique == el.unique
            );
            if (match) {
              const indexInCoupon =
                oddsByEventCopy[match.event_id] &&
                oddsByEventCopy[match.event_id].findIndex(
                  (item) => item.unique === match.unique
                );
              if (typeof indexInCoupon === "number" && indexInCoupon !== -1) {
                if (
                  oddsByEventCopy[match.event_id][indexInCoupon].value !==
                    match.value ||
                  oddsByEventCopy[match.event_id][indexInCoupon].locked !==
                    match.locked
                ) {
                  oddsByEventCopy[match.event_id][indexInCoupon] = {
                    ...oddsByEventCopy[match.event_id][indexInCoupon],
                    code: match.code,
                    value: match.value,
                    locked: match.locked,
                  };
                }
              }
            } else {
              if (el.eventId) {
                oddsByEventCopy[el.eventId] = oddsByEventCopy[
                  el.eventId
                ].filter((ar) => ar.unique !== el.unique);
                oddsByEventCopy[el.eventId].length === 0 &&
                  delete oddsByEventCopy[el.eventId];
              }
            }
          });
        });
        /** if an odd is removed, recalculate coupon */
        if (state.odds.length > oddsCopy.length) {
          state.couponType = calculateCouponType(
            oddsCopy,
            oddsCopy.length,
            Object.keys(state.coupon).length,
            state.couponTypeTab
          );

          if (state.couponTypeTab === 1) {
            /*************  system object calculations***********/
            const grCombinations = composeGrCombinations(
              oddsCopy.length,
              state.fixedArr
            );
            state.groupComb = grCombinations;
            /****************************************************/
            const updatedCheckedGroups = updateDataInCheckedGroups(
              updateCheckedGroupsOnOddRemove(
                state.checkedGroups,
                grCombinations
              ),
              state.bettingAmount,
              oddsCopy,
              state.fixedArr,
              state.bettingConfig?.bonus,
              state.bettingConfig?.setting?.rank_bonus,
              state.couponType && state.bettingConfig?.setting[state.couponType]
            );

            state.checkedGroups = updatedCheckedGroups;
            const checkedGroupsArray = Object.values(updatedCheckedGroups);
            const maxBonusValue = calculateSystemMaxBonus(
              checkedGroupsArray,
              state.couponType && state.bettingConfig?.setting[state.couponType]
            );
            const maxTotBonusValue = calculateSystemTotBonus(
              checkedGroupsArray,
              state.couponType && state.bettingConfig?.setting[state.couponType]
            );
            const maxWinValue = calculateSystemMaxWin(checkedGroupsArray);
            const maxTotWinValue = calculateSystemTotWin(checkedGroupsArray);
            const maxPotentialWinTotal = maxWinValue + maxBonusValue;
            const potentialWinValueTotal = maxTotBonusValue + maxTotWinValue;

            state.minBonus = calculateSystemMinBonus(
              checkedGroupsArray,
              state.couponType && state.bettingConfig?.setting[state.couponType]
            );
            state.maxBonus = maxBonusValue;
            state.minPotentialWin = calculateSystemMinWin(
              checkedGroupsArray,
              state.couponType && state.bettingConfig?.setting[state.couponType]
            );
            state.bettingConfig &&
              (state.maxPotentialWin =
                maxPotentialWinTotal >
                state.bettingConfig.setting[state.couponType]
                  ? state.bettingConfig.setting[state.couponType]
                  : maxPotentialWinTotal);
            state.bettingConfig &&
              (state.potentialWinValue =
                potentialWinValueTotal >
                state.bettingConfig.setting[state.couponType]
                  ? state.bettingConfig.setting[state.couponType]
                  : potentialWinValueTotal);
            state.isPotentialWinOverLimit =
              state.bettingConfig &&
              potentialWinValueTotal >
                state.bettingConfig.setting[state.couponType]
                ? true
                : false;

            /********************************************************************* */
          } else {
            state.combination = calculateNrOfCombinations(oddsByEventCopy);
            /** ********* find cartesian product of all odds *********************/
            const cartesianProductOfOdds = [
              ...new CartesianProduct(...Object.values(oddsByEventCopy)),
            ];
            const prodOfOddCombinations = calculateMultiplicationOfOddComb(
              cartesianProductOfOdds
            );
            const roundedAmount = (
              Number(state.bettingAmount) / state.combination
            )
              .toFixed(4)
              .toString();
            state.combinationAmount = roundedAmount;

            /** ********* create arryay of the product of all odd combinations ******/
            const calculatedMaxOdd = calculateArrayMaxMinValue(
              "max",
              prodOfOddCombinations
            );
            const calculatedMinOdd = calculateArrayMaxMinValue(
              "min",
              prodOfOddCombinations
            );
            state.maxOdd = calculatedMaxOdd;
            state.minOdd = calculatedMinOdd;
            const calculatedOddBonus = calculateOddBonus(
              cartesianProductOfOdds,
              state.bettingConfig?.bonus,
              state.bettingConfig?.setting?.rank_bonus
            );
            state.oddBonus = calculatedOddBonus;
            state.maxBonus =
              (calculateArrayMaxMinValue("max", state.oddBonus) *
                calculatedMaxOdd *
                Number(state.combinationAmount)) /
              100;
            state.minBonus =
              (calculateArrayMaxMinValue("min", state.oddBonus) *
                calculatedMinOdd *
                Number(state.combinationAmount)) /
              100;
            const calculatedPotWinArray = calculatePotentialWinArray(
              Number(roundedAmount),
              prodOfOddCombinations,
              calculatedOddBonus
            );
            state.potentialWinArray = calculatedPotWinArray;
            state.maxPotentialWin = calculateArrayMaxMinValue(
              "max",
              calculatedPotWinArray
            );
            state.minPotentialWin = calculateArrayMaxMinValue(
              "min",
              calculatedPotWinArray
            );
            const { isPotentialWinOverLimit, value } =
              calculatePotentialWinValue(
                state.potentialWinArray,
                state.bettingConfig?.setting[state.couponType]
              );
            state.potentialWinValue = value;
            state.isPotentialWinOverLimit = isPotentialWinOverLimit;
          }
        }
        state.odds = oddsCopy;
        state.coupon = oddsByEventCopy;
        state.couponLength = oddsCopy.length;
      }
    },
    getOddsUpdatesFailed: (state: CouponState) => {
      state.isFetchingOddsUpdate = false;
    },
    updateCalculationsForOddChange: (state: CouponState) => {
      /** ********* find coupon type************************************/
      state.couponType = calculateCouponType(
        state.odds,
        state.couponLength,
        Object.keys(state.coupon).length,
        state.couponTypeTab
      );
      /** ********* ***********************************************/

      if (state.couponTypeTab === 1) {
        const updatedCheckedGroups = updatePotentialWinsInCheckedGroups(
          state.checkedGroups,
          state.groupComb,
          state.odds,
          state.fixedArr
        );
        state.checkedGroups = updatedCheckedGroups;
        const checkedGroupsArray = Object.values(updatedCheckedGroups);
        const maxBonusValue = calculateSystemMaxBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        const maxTotBonusValue = calculateSystemTotBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        const maxWinValue = calculateSystemMaxWin(checkedGroupsArray);
        const maxTotWinValue = calculateSystemTotWin(checkedGroupsArray);
        const maxPotentialWinTotal = maxWinValue + maxBonusValue;
        const potentialWinValueTotal = maxTotBonusValue + maxTotWinValue;

        state.minBonus = calculateSystemMinBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        state.maxBonus = maxBonusValue;
        state.minPotentialWin = calculateSystemMinWin(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        state.bettingConfig &&
          (state.maxPotentialWin =
            maxPotentialWinTotal > state.bettingConfig.setting[state.couponType]
              ? state.bettingConfig.setting[state.couponType]
              : maxPotentialWinTotal);
        state.bettingConfig &&
          (state.potentialWinValue =
            potentialWinValueTotal >
            state.bettingConfig.setting[state.couponType]
              ? state.bettingConfig.setting[state.couponType]
              : potentialWinValueTotal);
        state.isPotentialWinOverLimit =
          state.bettingConfig &&
          potentialWinValueTotal > state.bettingConfig.setting[state.couponType]
            ? true
            : false;
      } else {
        if (
          state.couponTypeTab !== 1 &&
          state.couponLength !== Object.keys(state.coupon).length &&
          state.bettingConfig &&
          state.combination &&
          state.combination >= state.bettingConfig?.setting.max_col_integral
        ) {
          return;
        }
        /** ********* find cartesian product of all odds *********************/
        const cartesianProductOfOdds = [
          ...new CartesianProduct(...Object.values(state.coupon)),
        ];
        const prodOfOddCombinations = calculateMultiplicationOfOddComb(
          cartesianProductOfOdds
        );
        const roundedAmount =
          state.combination &&
          (Number(state.bettingAmount) / state.combination)
            .toFixed(4)
            .toString();
        roundedAmount && (state.combinationAmount = roundedAmount);
        /** ********* create arryay of the product of all odd combinations ******/
        const calculatedMaxOdd = calculateArrayMaxMinValue(
          "max",
          prodOfOddCombinations
        );
        const calculatedMinOdd = calculateArrayMaxMinValue(
          "min",
          prodOfOddCombinations
        );
        state.maxOdd = calculatedMaxOdd;
        state.minOdd = calculatedMinOdd;
        const calculatedOddBonus = calculateOddBonus(
          cartesianProductOfOdds,
          state.bettingConfig?.bonus,
          state.bettingConfig?.setting?.rank_bonus
        );
        state.oddBonus = calculatedOddBonus;
        state.maxBonus =
          (calculateArrayMaxMinValue("max", calculatedOddBonus) *
            calculatedMaxOdd *
            Number(roundedAmount)) /
          100;
        state.minBonus =
          (calculateArrayMaxMinValue("min", calculatedOddBonus) *
            calculatedMinOdd *
            Number(roundedAmount)) /
          100;
        const calculatedPotWinArray = calculatePotentialWinArray(
          Number(roundedAmount),
          prodOfOddCombinations,
          calculatedOddBonus
        );
        state.potentialWinArray = calculatedPotWinArray;
        state.maxPotentialWin = calculateArrayMaxMinValue(
          "max",
          calculatedPotWinArray
        );
        state.minPotentialWin = calculateArrayMaxMinValue(
          "min",
          calculatedPotWinArray
        );
        const { isPotentialWinOverLimit, value } = calculatePotentialWinValue(
          calculatedPotWinArray,
          state.bettingConfig?.setting[state.couponType]
        );
        state.potentialWinValue = value;
        state.isPotentialWinOverLimit = isPotentialWinOverLimit;
      }
    },
    removeOddFromCoupon: (
      state: CouponState,
      action: PayloadAction<IRemoveItemPayload>
    ) => {
      const { eventId, unique } = action.payload;
      state.couponLength -= 1;
      state.odds = state.odds.filter((el) => el.unique !== unique);
      eventId &&
        (state.coupon[eventId].filter((el) => el.unique !== unique).length > 0
          ? (state.coupon[eventId] = state.coupon[eventId].filter(
              (el) => el.unique !== unique
            ))
          : delete state.coupon[eventId]);
      if (state.couponLength === 0) {
        state.couponType = undefined;
        state.maxOdd = undefined;
        state.minOdd = undefined;
        state.maxBonus = undefined;
        state.minBonus = undefined;
        state.maxPotentialWin = undefined;
        state.minPotentialWin = undefined;
        state.bettingAmount = "";
        state.combinationAmount = "";
        state.potentialWinValue = undefined;
        state.combination = undefined;
        state.potentialWinArray = undefined;
        state.oddBonus = undefined;
        state.couponType = undefined;
        state.fixedArr = [];
        state.checkedGroups = {};
        state.groupComb = {};
        state.couponTypeTab = 0;
        state.isPotentialWinOverLimit = false;
      } else {
        state.couponType = calculateCouponType(
          state.odds,
          state.couponLength,
          Object.keys(state.coupon).length,
          state.couponTypeTab
        );
        if (state.couponTypeTab === 1) {
          const grCombinations = composeGrCombinations(
            state.odds.length,
            state.fixedArr
          );
          state.groupComb = grCombinations;
          /**************************************************************/
          const updatedCheckedGroups = updateDataInCheckedGroups(
            updateCheckedGroupsOnOddRemove(state.checkedGroups, grCombinations),
            state.bettingAmount,
            state.odds,
            state.fixedArr,
            state.bettingConfig?.bonus,
            state.bettingConfig?.setting?.rank_bonus,
            state.couponType && state.bettingConfig?.setting[state.couponType]
          );
          state.checkedGroups = updatedCheckedGroups;
          const checkedGroupsArray = Object.values(updatedCheckedGroups);
          const maxBonusValue = calculateSystemMaxBonus(
            checkedGroupsArray,
            state.couponType && state.bettingConfig?.setting[state.couponType]
          );
          const maxTotBonusValue = calculateSystemTotBonus(
            checkedGroupsArray,
            state.couponType && state.bettingConfig?.setting[state.couponType]
          );
          const maxWinValue = calculateSystemMaxWin(checkedGroupsArray);
          const maxTotWinValue = calculateSystemTotWin(checkedGroupsArray);
          const maxPotentialWinTotal = maxWinValue + maxBonusValue;
          const potentialWinValueTotal = maxTotBonusValue + maxTotWinValue;

          state.minBonus = calculateSystemMinBonus(
            checkedGroupsArray,
            state.couponType && state.bettingConfig?.setting[state.couponType]
          );
          state.maxBonus = maxBonusValue;
          state.minPotentialWin = calculateSystemMinWin(
            checkedGroupsArray,
            state.couponType && state.bettingConfig?.setting[state.couponType]
          );
          state.bettingConfig &&
            (state.maxPotentialWin =
              maxPotentialWinTotal >
              state.bettingConfig.setting[state.couponType]
                ? state.bettingConfig.setting[state.couponType]
                : maxPotentialWinTotal);
          state.bettingConfig &&
            (state.potentialWinValue =
              potentialWinValueTotal >
              state.bettingConfig.setting[state.couponType]
                ? state.bettingConfig.setting[state.couponType]
                : potentialWinValueTotal);
          state.isPotentialWinOverLimit =
            state.bettingConfig &&
            potentialWinValueTotal >
              state.bettingConfig.setting[state.couponType]
              ? true
              : false;
        } else {
          state.combination = calculateNrOfCombinations(state.coupon);
          /** ********* find cartesian product of all odds *********************/
          const cartesianProductOfOdds = [
            ...new CartesianProduct(...Object.values(state.coupon)),
          ];
          const prodOfOddCombinations = calculateMultiplicationOfOddComb(
            cartesianProductOfOdds
          );
          const roundedAmount = (
            Number(state.bettingAmount) / state.combination
          )
            .toFixed(4)
            .toString();
          state.combinationAmount = roundedAmount;
          const calculatedMaxOdd = calculateArrayMaxMinValue(
            "max",
            prodOfOddCombinations
          );
          const calculatedMinOdd = calculateArrayMaxMinValue(
            "min",
            prodOfOddCombinations
          );
          state.maxOdd = calculatedMaxOdd;
          state.minOdd = calculatedMinOdd;
          const calculatedOddBonus = calculateOddBonus(
            cartesianProductOfOdds,
            state.bettingConfig?.bonus,
            state.bettingConfig?.setting?.rank_bonus
          );
          state.oddBonus = calculatedOddBonus;
          state.maxBonus =
            (calculateArrayMaxMinValue("max", state.oddBonus) *
              calculatedMaxOdd *
              Number(state.combinationAmount)) /
            100;
          state.minBonus =
            (calculateArrayMaxMinValue("min", state.oddBonus) *
              calculatedMinOdd *
              Number(roundedAmount)) /
            100;

          const calculatedPotWinArray = calculatePotentialWinArray(
            Number(roundedAmount),
            prodOfOddCombinations,
            calculatedOddBonus
          );
          state.potentialWinArray = calculatedPotWinArray;
          state.maxPotentialWin = calculateArrayMaxMinValue(
            "max",
            calculatedPotWinArray
          );
          state.minPotentialWin = calculateArrayMaxMinValue(
            "min",
            calculatedPotWinArray
          );
          const { isPotentialWinOverLimit, value } =
            (state.potentialWinValue &&
              calculatePotentialWinValue(
                state.potentialWinArray,
                state.bettingConfig?.setting[state.couponType]
              )) ||
            {};

          state.potentialWinValue = value;
          state.isPotentialWinOverLimit = isPotentialWinOverLimit
            ? isPotentialWinOverLimit
            : false;
        }
      }
    },
    removeEventFromCoupon: (
      state: CouponState,
      action: PayloadAction<string>
    ) => {
      state.couponLength -= state.coupon[action.payload].length;
      state.odds = state.odds.filter(
        (el) => el.eventId !== Number(action.payload)
      );
      delete state.coupon[action.payload];

      if (state.couponLength === 0) {
        state.couponType = undefined;
        state.maxOdd = undefined;
        state.minOdd = undefined;
        state.maxBonus = undefined;
        state.minBonus = undefined;
        state.maxPotentialWin = undefined;
        state.minPotentialWin = undefined;
        state.bettingAmount = "";
        state.combinationAmount = "";
        state.potentialWinValue = undefined;
        state.combination = undefined;
        state.potentialWinArray = undefined;
        state.oddBonus = undefined;
        state.couponType = undefined;
        state.fixedArr = [];
        state.checkedGroups = {};
        state.groupComb = {};
        state.couponTypeTab = 0;
        state.isPotentialWinOverLimit = false;
      } else {
        state.couponType = calculateCouponType(
          state.odds,
          state.couponLength,
          Object.keys(state.coupon).length,
          state.couponTypeTab
        );

        if (state.couponTypeTab === 1) {
          /*************  system object calculations***********/
          const grCombinations = composeGrCombinations(
            state.odds.length,
            state.fixedArr
          );
          state.groupComb = grCombinations;
          /****************************************************/
          const updatedCheckedGroups = updateDataInCheckedGroups(
            updateCheckedGroupsOnOddRemove(state.checkedGroups, grCombinations),
            state.bettingAmount,
            state.odds,
            state.fixedArr,
            state.bettingConfig?.bonus,
            state.bettingConfig?.setting?.rank_bonus,
            state.couponType && state.bettingConfig?.setting[state.couponType]
          );

          state.checkedGroups = updatedCheckedGroups;
          const checkedGroupsArray = Object.values(updatedCheckedGroups);
          const maxBonusValue = calculateSystemMaxBonus(
            checkedGroupsArray,
            state.couponType && state.bettingConfig?.setting[state.couponType]
          );
          const maxTotBonusValue = calculateSystemTotBonus(
            checkedGroupsArray,
            state.couponType && state.bettingConfig?.setting[state.couponType]
          );
          const maxWinValue = calculateSystemMaxWin(checkedGroupsArray);
          const maxTotWinValue = calculateSystemTotWin(checkedGroupsArray);
          const maxPotentialWinTotal = maxWinValue + maxBonusValue;
          const potentialWinValueTotal = maxTotBonusValue + maxTotWinValue;

          state.minBonus = calculateSystemMinBonus(
            checkedGroupsArray,
            state.couponType && state.bettingConfig?.setting[state.couponType]
          );
          state.maxBonus = maxBonusValue;
          state.minPotentialWin = calculateSystemMinWin(
            checkedGroupsArray,
            state.couponType && state.bettingConfig?.setting[state.couponType]
          );
          state.bettingConfig &&
            (state.maxPotentialWin =
              maxPotentialWinTotal >
              state.bettingConfig.setting[state.couponType]
                ? state.bettingConfig.setting[state.couponType]
                : maxPotentialWinTotal);
          state.bettingConfig &&
            (state.potentialWinValue =
              potentialWinValueTotal >
              state.bettingConfig.setting[state.couponType]
                ? state.bettingConfig.setting[state.couponType]
                : potentialWinValueTotal);
          state.isPotentialWinOverLimit =
            state.bettingConfig &&
            potentialWinValueTotal >
              state.bettingConfig.setting[state.couponType]
              ? true
              : false;

          /********************************************************************* */
        } else {
          state.combination = calculateNrOfCombinations(state.coupon);
          /** ********* find cartesian product of all odds *********************/
          const cartesianProductOfOdds = [
            ...new CartesianProduct(...Object.values(state.coupon)),
          ];
          const prodOfOddCombinations = calculateMultiplicationOfOddComb(
            cartesianProductOfOdds
          );
          const roundedAmount = (
            Number(state.bettingAmount) / state.combination
          )
            .toFixed(4)
            .toString();
          state.combinationAmount = roundedAmount;

          /** ********* create arryay of the product of all odd combinations ******/
          const calculatedMaxOdd = calculateArrayMaxMinValue(
            "max",
            prodOfOddCombinations
          );
          const calculatedMinOdd = calculateArrayMaxMinValue(
            "min",
            prodOfOddCombinations
          );
          state.maxOdd = calculatedMaxOdd;
          state.minOdd = calculatedMinOdd;
          const calculatedOddBonus = calculateOddBonus(
            cartesianProductOfOdds,
            state.bettingConfig?.bonus,
            state.bettingConfig?.setting?.rank_bonus
          );
          state.oddBonus = calculatedOddBonus;
          state.maxBonus =
            (calculateArrayMaxMinValue("max", state.oddBonus) *
              calculatedMaxOdd *
              Number(state.combinationAmount)) /
            100;
          state.minBonus =
            (calculateArrayMaxMinValue("min", state.oddBonus) *
              calculatedMinOdd *
              Number(state.combinationAmount)) /
            100;
          const calculatedPotWinArray = calculatePotentialWinArray(
            Number(roundedAmount),
            prodOfOddCombinations,
            calculatedOddBonus
          );
          state.potentialWinArray = calculatedPotWinArray;
          state.maxPotentialWin = calculateArrayMaxMinValue(
            "max",
            calculatedPotWinArray
          );
          state.minPotentialWin = calculateArrayMaxMinValue(
            "min",
            calculatedPotWinArray
          );
          const { isPotentialWinOverLimit, value } = calculatePotentialWinValue(
            state.potentialWinArray,
            state.bettingConfig?.setting[state.couponType]
          );
          state.potentialWinValue = value;
          state.isPotentialWinOverLimit = isPotentialWinOverLimit;
        }
      }
    },
    saveCheckedGroup: (
      state: CouponState,
      action: PayloadAction<NewGroupPayload>
    ) => {
      const { group, nrComb } = action.payload;
      let updatedCheckedGroups;
      if (state.checkedGroups[group]) {
        delete state.checkedGroups[group];
        updatedCheckedGroups = updateDataInCheckedGroups(
          state.checkedGroups,
          Number(state.bettingAmount),
          state.odds,
          state.fixedArr,
          state.bettingConfig?.bonus,
          state.bettingConfig?.setting?.rank_bonus,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
      } else {
        updatedCheckedGroups = updateDataInCheckedGroups(
          {
            ...state.checkedGroups,
            [group]: {
              combination: nrComb,
            },
          },
          Number(state.bettingAmount),
          state.odds,
          state.fixedArr,
          state.bettingConfig?.bonus,
          state.bettingConfig?.setting?.rank_bonus,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
      }
      state.checkedGroups = updatedCheckedGroups;
      const checkedGroupsArray = Object.values(updatedCheckedGroups);

      const maxBonusValue = calculateSystemMaxBonus(
        checkedGroupsArray,
        state.couponType && state.bettingConfig?.setting[state.couponType]
      );
      const maxTotBonusValue = calculateSystemTotBonus(
        checkedGroupsArray,
        state.couponType && state.bettingConfig?.setting[state.couponType]
      );
      const maxWinValue = calculateSystemMaxWin(checkedGroupsArray);
      const maxTotWinValue = calculateSystemTotWin(checkedGroupsArray);
      const maxPotentialWinTotal = maxWinValue + maxBonusValue;
      const potentialWinValueTotal = maxTotBonusValue + maxTotWinValue;

      state.minBonus = calculateSystemMinBonus(
        checkedGroupsArray,
        state.couponType && state.bettingConfig?.setting[state.couponType]
      );
      state.maxBonus = maxBonusValue;
      state.minPotentialWin = calculateSystemMinWin(
        checkedGroupsArray,
        state.couponType && state.bettingConfig?.setting[state.couponType]
      );
      state.bettingConfig &&
        state.couponType &&
        (state.maxPotentialWin =
          maxPotentialWinTotal > state.bettingConfig.setting[state.couponType]
            ? state.bettingConfig.setting[state.couponType]
            : maxPotentialWinTotal);
      state.bettingConfig &&
        state.couponType &&
        (state.potentialWinValue =
          potentialWinValueTotal > state.bettingConfig.setting[state.couponType]
            ? state.bettingConfig.setting[state.couponType]
            : potentialWinValueTotal);
      state.isPotentialWinOverLimit =
        state.bettingConfig &&
        state.couponType &&
        potentialWinValueTotal > state.bettingConfig.setting[state.couponType]
          ? true
          : false;
    },
    updateCombinations: (
      state: CouponState,
      action: PayloadAction<NewGroupPayload>
    ) => {
      const { group, nrComb } = action.payload;
      state.checkedGroups = updateDataInCheckedGroups(
        {
          ...state.checkedGroups,
          [group]: {
            ...state.checkedGroups[group],
            combination: nrComb,
          },
        },
        Number(state.bettingAmount),
        state.odds,
        state.fixedArr,
        state.bettingConfig?.bonus,
        state.bettingConfig?.setting?.rank_bonus,
        state.couponType && state.bettingConfig?.setting[state.couponType]
      );
    },
    updateAmounts: (state: CouponState) => {
      state.checkedGroups = updateDataInCheckedGroups(
        state.checkedGroups,
        Number(state.bettingAmount),
        state.odds,
        state.fixedArr,
        state.bettingConfig?.bonus,
        state.bettingConfig?.setting?.rank_bonus,
        state.couponType && state.bettingConfig?.setting[state.couponType]
      );
    },
    saveFixedOdds: (
      state: CouponState,
      action: PayloadAction<NewFixedOddPayload>
    ) => {
      const { fix, unique, code } = action.payload;

      state.fixedArr = state.fixedArr.find((el) => el.unique === unique)
        ? state.fixedArr.filter((el) => el.unique !== unique)
        : [
            ...state.fixedArr.filter((el) => el.unique !== unique),
            {
              unique: unique,
              fix: fix,
              code: code,
            },
          ];

      /*************  system object calculations********************************/
      const grCombinations = composeGrCombinations(
        state.odds.length,
        state.fixedArr
      );
      state.groupComb = grCombinations;
      /**************************************************************/
      const updatedCheckedGroups = updateDataInCheckedGroups(
        updateCheckedGroupsOnOddRemove(state.checkedGroups, grCombinations),
        state.bettingAmount,
        state.odds,
        state.fixedArr,
        state.bettingConfig?.bonus,
        state.bettingConfig?.setting?.rank_bonus,
        state.couponType && state.bettingConfig?.setting[state.couponType]
      );
      state.checkedGroups = updatedCheckedGroups;
      const checkedGroupsArray = Object.values(updatedCheckedGroups);

      const maxBonusValue = calculateSystemMaxBonus(
        checkedGroupsArray,
        state.couponType && state.bettingConfig?.setting[state.couponType]
      );
      const maxTotBonusValue = calculateSystemTotBonus(
        checkedGroupsArray,
        state.couponType && state.bettingConfig?.setting[state.couponType]
      );
      const maxWinValue = calculateSystemMaxWin(checkedGroupsArray);
      const maxTotWinValue = calculateSystemTotWin(checkedGroupsArray);
      const maxPotentialWinTotal = maxWinValue + maxBonusValue;
      const potentialWinValueTotal = maxTotBonusValue + maxTotWinValue;

      state.minBonus = calculateSystemMinBonus(
        checkedGroupsArray,
        state.couponType && state.bettingConfig?.setting[state.couponType]
      );
      state.maxBonus = maxBonusValue;
      state.minPotentialWin = calculateSystemMinWin(
        Object.values(state.checkedGroups),
        state.couponType && state.bettingConfig?.setting[state.couponType]
      );
      state.bettingConfig &&
        state.couponType &&
        (state.maxPotentialWin =
          maxPotentialWinTotal > state.bettingConfig.setting[state.couponType]
            ? state.bettingConfig.setting[state.couponType]
            : maxPotentialWinTotal);
      state.bettingConfig &&
        state.couponType &&
        (state.potentialWinValue =
          potentialWinValueTotal > state.bettingConfig.setting[state.couponType]
            ? state.bettingConfig.setting[state.couponType]
            : potentialWinValueTotal);
      state.isPotentialWinOverLimit =
        state.bettingConfig &&
        state.couponType &&
        potentialWinValueTotal > state.bettingConfig.setting[state.couponType]
          ? true
          : false;
    },
    saveBettingAmount: (state: CouponState, action: PayloadAction<string>) => {
      const roundedBAmount = action.payload;
      if (state.couponTypeTab === 1) {
        state.bettingAmount = roundedBAmount;
        const updatedCheckedGroups = updateDataInCheckedGroups(
          state.checkedGroups,
          action.payload.length === 0 ? "0" : action.payload,
          state.odds,
          state.fixedArr,
          state.bettingConfig?.bonus,
          state.bettingConfig?.setting?.rank_bonus,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        state.checkedGroups = updatedCheckedGroups;
        const checkedGroupsArray = Object.values(updatedCheckedGroups);
        const maxBonusValue = calculateSystemMaxBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        const maxTotBonusValue = calculateSystemTotBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        const maxWinValue = calculateSystemMaxWin(checkedGroupsArray);
        const maxTotWinValue = calculateSystemTotWin(checkedGroupsArray);
        const maxPotentialWinTotal = maxWinValue + maxBonusValue;
        const potentialWinValueTotal = maxTotBonusValue + maxTotWinValue;

        state.minBonus = calculateSystemMinBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        state.maxBonus = maxBonusValue;
        state.minPotentialWin = calculateSystemMinWin(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        state.bettingConfig &&
          state.couponType &&
          (state.maxPotentialWin =
            maxPotentialWinTotal > state.bettingConfig.setting[state.couponType]
              ? state.bettingConfig.setting[state.couponType]
              : maxPotentialWinTotal);

        state.bettingConfig &&
          state.couponType &&
          (state.potentialWinValue =
            potentialWinValueTotal >
            state.bettingConfig.setting[state.couponType]
              ? state.bettingConfig.setting[state.couponType]
              : potentialWinValueTotal);
        state.isPotentialWinOverLimit =
          state.bettingConfig &&
          state.couponType &&
          potentialWinValueTotal > state.bettingConfig.setting[state.couponType]
            ? true
            : false;
      } else {
        /** ********* find cartesian product of all odds ***********/

        state.bettingAmount = roundedBAmount;

        state.combination &&
          (state.combinationAmount = (
            Number(action.payload) / state.combination
          )
            .toFixed(4)
            .toString());
        const cartesianProductOfOdds = [
          ...new CartesianProduct(...Object.values(state.coupon)),
        ];
        const prodOfOddCombinations = calculateMultiplicationOfOddComb(
          cartesianProductOfOdds
        );

        /** ********* create arryay of the product of all odd combinations ******/
        const calculatedMaxOdd = calculateArrayMaxMinValue(
          "max",
          prodOfOddCombinations
        );
        const calculatedMinOdd = calculateArrayMaxMinValue(
          "min",
          prodOfOddCombinations
        );

        state.maxOdd = calculatedMaxOdd;
        state.minOdd = calculatedMinOdd;
        const calculatedOddBonus = calculateOddBonus(
          cartesianProductOfOdds,
          state.bettingConfig?.bonus,
          state.bettingConfig?.setting?.rank_bonus
        );
        state.oddBonus = calculatedOddBonus;
        state.maxBonus =
          (calculateArrayMaxMinValue("max", state.oddBonus) *
            calculatedMaxOdd *
            Number(state.combinationAmount)) /
          100;
        state.minBonus =
          (calculateArrayMaxMinValue("min", state.oddBonus) *
            calculatedMinOdd *
            Number(state.combinationAmount)) /
          100;
        const calculatedPotWinArray = calculatePotentialWinArray(
          Number(state.combinationAmount),
          prodOfOddCombinations,
          calculatedOddBonus
        );
        state.potentialWinArray = calculatedPotWinArray;
        state.maxPotentialWin = calculateArrayMaxMinValue(
          "max",
          calculatedPotWinArray
        );
        state.minPotentialWin = calculateArrayMaxMinValue(
          "min",
          calculatedPotWinArray
        );

        const { isPotentialWinOverLimit, value } =
          (state.couponType &&
            calculatePotentialWinValue(
              calculatedPotWinArray,
              state.bettingConfig?.setting[state.couponType]
            )) ||
          {};
        state.potentialWinValue = value;
        state.isPotentialWinOverLimit = isPotentialWinOverLimit
          ? isPotentialWinOverLimit
          : false;
      }
    },
    saveBettingConfigSucceded: (
      state: CouponState,
      action: PayloadAction<BettingConfig>
    ) => {
      state.bettingConfig = action.payload;

      /** ********* find coupon type *****************************/
      state.couponType = calculateCouponType(
        state.odds,
        state.couponLength,
        Object.keys(state.coupon).length,
        state.couponTypeTab
      );
      /** *********************************************************/

      if (state.couponTypeTab === 1) {
        const updatedCheckedGroups = updatePotentialWinsInCheckedGroups(
          updateCheckedGroupsOnCombRemove(
            state.checkedGroups,
            state.groupComb,
            action.payload.setting?.max_col_system
          ),
          state.groupComb,
          state.odds,
          state.fixedArr
        );
        state.checkedGroups = updatedCheckedGroups;
        const checkedGroupsArray = Object.values(updatedCheckedGroups);
        const maxBonusValue = calculateSystemMaxBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        const maxTotBonusValue = calculateSystemTotBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        const maxWinValue = calculateSystemMaxWin(checkedGroupsArray);
        const maxTotWinValue = calculateSystemTotWin(checkedGroupsArray);
        const maxPotentialWinTotal = maxWinValue + maxBonusValue;
        const potentialWinValueTotal = maxTotBonusValue + maxTotWinValue;

        state.minBonus = calculateSystemMinBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        state.maxBonus = maxBonusValue;
        state.minPotentialWin = calculateSystemMinWin(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        state.bettingConfig &&
          (state.maxPotentialWin =
            maxPotentialWinTotal > state.bettingConfig.setting[state.couponType]
              ? state.bettingConfig.setting[state.couponType]
              : maxPotentialWinTotal);

        state.bettingConfig &&
          (state.potentialWinValue =
            potentialWinValueTotal >
            state.bettingConfig.setting[state.couponType]
              ? state.bettingConfig.setting[state.couponType]
              : potentialWinValueTotal);
        state.isPotentialWinOverLimit =
          potentialWinValueTotal > state.bettingConfig.setting[state.couponType]
            ? true
            : false;
      } else {
        const { isPotentialWinOverLimit, value } =
          (state.potentialWinArray &&
            calculatePotentialWinValue(
              state.potentialWinArray,
              state.bettingConfig?.setting[state.couponType]
            )) ||
          {};

        state.potentialWinValue = value;
        state.isPotentialWinOverLimit = isPotentialWinOverLimit
          ? isPotentialWinOverLimit
          : false;
      }
    },
    saveCombinationAmount: (
      state: CouponState,
      action: PayloadAction<NewCombAmountPayload>
    ) => {
      const { type, data } = action.payload;
      const { group, combinationAmount, nrComb } = data;
      if (type === "system") {
        if (group && nrComb) {
          const combinations = calculateAllCombinationsForCheckedGroups(
            state.odds,
            group,
            state.fixedArr
          );
          const bonusArray = calculateBonusArrayPerGroup(
            combinations,
            Number(combinationAmount),
            state.bettingConfig?.bonus,
            state.bettingConfig?.setting?.rank_bonus
          );

          state.checkedGroups = {
            ...state.checkedGroups,
            [group]: state.checkedGroups[group]
              ? {
                  ...state.checkedGroups[group],
                  ["combinationAmount"]: combinationAmount,
                  ["bettingAmount"]: (
                    Number(combinationAmount) * nrComb
                  ).toString(),
                  ["maxBonus"]: calculateSystemGrMaxBonus(bonusArray),
                  ["minBonus"]: calculateSystemGrMinBonus(bonusArray),
                }
              : {
                  ["minPotentialWin"]:
                    calculateMultiplicationOfSystemOddCombMin(combinations) *
                    Number(combinationAmount),
                  ["maxPotentialWin"]:
                    calculateMultiplicationOfSystemOddComb(combinations) *
                    Number(combinationAmount),
                  ["maxBonus"]: calculateSystemGrMaxBonus(bonusArray),
                  ["minBonus"]: calculateSystemGrMinBonus(bonusArray),
                  ["combination"]: nrComb,
                  ["combinationAmount"]: combinationAmount,
                  ["bettingAmount"]: (
                    Number(combinationAmount) * nrComb
                  ).toString(),
                },
          };
        }

        state.bettingAmount = calculateBettingAmount(state.checkedGroups);

        const checkedGroupsArray = Object.values(state.checkedGroups);

        const maxBonusValue = calculateSystemMaxBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        const maxTotBonusValue = calculateSystemTotBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        const maxWinValue = calculateSystemMaxWin(checkedGroupsArray);
        const maxTotWinValue = calculateSystemTotWin(checkedGroupsArray);
        const maxPotentialWinTotal = maxWinValue + maxBonusValue;
        const potentialWinValueTotal = maxTotBonusValue + maxTotWinValue;

        state.minBonus = calculateSystemMinBonus(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        state.maxBonus = maxBonusValue;
        state.minPotentialWin = calculateSystemMinWin(
          checkedGroupsArray,
          state.couponType && state.bettingConfig?.setting[state.couponType]
        );
        state.bettingConfig &&
          state.couponType &&
          (state.maxPotentialWin =
            maxPotentialWinTotal > state.bettingConfig.setting[state.couponType]
              ? state.bettingConfig.setting[state.couponType]
              : maxPotentialWinTotal);
        state.bettingConfig &&
          state.couponType &&
          (state.potentialWinValue =
            potentialWinValueTotal >
            state.bettingConfig.setting[state.couponType]
              ? state.bettingConfig.setting[state.couponType]
              : potentialWinValueTotal);
        state.isPotentialWinOverLimit =
          state.bettingConfig &&
          state.couponType &&
          potentialWinValueTotal > state.bettingConfig.setting[state.couponType]
            ? true
            : false;
      } else {
        /** ********* find cartesian product of all odds *********************/
        const cartesianProductOfOdds = [
          ...new CartesianProduct(...Object.values(state.coupon)),
        ];
        const prodOfOddCombinations = calculateMultiplicationOfOddComb(
          cartesianProductOfOdds
        );
        state.combinationAmount = combinationAmount;
        state.combination &&
          (state.bettingAmount = (Number(combinationAmount) * state.combination)
            .toFixed(2)
            .toString());
        state.potentialWinArray = calculatePotentialWinArray(
          Number(state.combinationAmount),
          prodOfOddCombinations,
          state.oddBonus
        );
        state.maxPotentialWin = calculateArrayMaxMinValue(
          "max",
          state.potentialWinArray
        );
        state.minPotentialWin = calculateArrayMaxMinValue(
          "min",
          state.potentialWinArray
        );
        const { isPotentialWinOverLimit, value } =
          (state.couponType &&
            calculatePotentialWinValue(
              state.potentialWinArray,
              state.bettingConfig?.setting[state.couponType]
            )) ||
          {};

        state.potentialWinValue = value;
        state.isPotentialWinOverLimit = isPotentialWinOverLimit
          ? isPotentialWinOverLimit
          : false;

        state.maxOdd &&
          (state.maxBonus =
            (calculateArrayMaxMinValue("max", state.oddBonus) *
              state.maxOdd *
              Number(state.combinationAmount)) /
            100);
        state.minOdd &&
          (state.minBonus =
            (calculateArrayMaxMinValue("min", state.oddBonus) *
              state.minOdd *
              Number(state.combinationAmount)) /
            100);
      }
    },
    saveCouponRequestPayload: (
      state: CouponState,
      action: PayloadAction<CouponRequestPayload>
    ) => {
      state.couponRequestPayload = action.payload;
    },
    playCouponRequested: (
      state: CouponState,
      action: PayloadAction<CouponRequestPayload>
    ) => {
      state.isFetchingPlayedCoupon = true;
      state.playedCouponState && (state.playedCouponState = undefined);
      state.couponRequestPayload = undefined;
      state.proceedState = false;
      state.isPlayed = true;
      state.isBooked && (state.isBooked = false);
      action;
    },
    playCouponSucceded: (
      state: CouponState,
      action: PayloadAction<PlayedCouponState>
    ) => {
      state.isFetchingPlayedCoupon = false;
      state.playedCouponState = action.payload;
      action.payload
        ? (state.playedCouponsStates = {
            [action.payload.id]: action.payload,
            ...state.playedCouponsStates,
          })
        : (state.playedCouponsStates = undefined);
    },
    playCouponFailed: (state: CouponState) => {
      state.isFetchingPlayedCoupon = false;
    },
    updatePlayedCuponStates: (
      state: CouponState,
      action: PayloadAction<string>
    ) => {
      state.playedCouponsStates &&
        delete state.playedCouponsStates[action.payload];
      state.isPlayed = false;
      state.playedCouponState = undefined;
    },
    bookCouponRequested: (
      state: CouponState,
      action: PayloadAction<CouponRequestPayload>
    ) => {
      state.isFetching = true;
      state.playedCouponState && (state.playedCouponState = undefined);
      state.bookState = false;
      state.isBooked = true;
      state.isPlayed && (state.isPlayed = false);
      action;
    },
    bookCouponSucceded: (
      state: CouponState,
      action: PayloadAction<PlayedCouponState>
    ) => {
      state.isFetching = false;
      state.playedCouponState = action.payload;
    },
    bookCouponFailed: (state: CouponState) => {
      state.isFetching = false;
    },
    printPlayedCouponRequested: (
      state: CouponState,
      action: PayloadAction<string>
    ) => {
      state.isFetchingCouponForPrint = true;
      action;
    },
    printPlayedCouponSucceded: (
      state: CouponState,
      action: PayloadAction<PlayedCoupon>
    ) => {
      state.isFetchingCouponForPrint = false;
      action;
    },
    printPlayedCouponFailed: (state: CouponState) => {
      state.isFetchingCouponForPrint = false;
    },
    verifyPlayedCouponRequested: (
      state: CouponState,
      action: PayloadAction<any>
    ) => {
      state.isFetching = true;
      action;
    },
    verifyPlayedCouponSucceded: (
      state: CouponState,
      action: PayloadAction<PlayedCoupon>
    ) => {
      state.isFetching = false;
      state.playedCoupon = {
        ...action.payload,
        isVerifyAction: true,
      };
      if (action.payload.caller !== "route") {
        state.isPlayedCouponReceiptVisible = true;
      }
    },
    verifyPlayedCouponFailed: (state: CouponState) => {
      state.isFetching = false;
    },
    getPlayedCouponRequested: (
      state: CouponState,
      action: PayloadAction<any>
    ) => {
      state.isFetchingPlayedCoupon = true;
      action;
    },
    getPlayedCouponSucceded: (
      state: CouponState,
      action: PayloadAction<PlayedCouponResponse>
    ) => {
      state.isFetchingPlayedCoupon = false;
      const { data, isRepeated, divider } = action.payload;
      const dividerFromState = divider ? divider : 1;
      state.playedCoupon = data;
      if (isRepeated) {
        state.coupon = composeCouponData(data);
        state.odds = composeCouponOdds(data);
        state.couponLength = data.odds.length;
        state.bettingAmount = dividerFromState
          ? (data.bet_amount / dividerFromState).toFixed(2).toString()
          : data.bet_amount.toFixed(2).toString();

        /** ********* set limits *************/
        state.couponType = calculateCouponType(
          state.odds,
          state.couponLength,
          Object.keys(state.coupon).length,
          data.systems.length > 0 ? 1 : 0
        );
        /**********************************/

        if (data.systems.length > 0) {
          state.couponTypeTab = 1;
          /**system calculations */
          let checkedGr = {};
          data.systems.map((group) => {
            checkedGr = {
              ...checkedGr,
              [group.group]: {
                combination: group.comb,
                combinationAmount: group.bet_amount,
              },
            };
          });
          state.fixedArr = data.fixed;
          state.groupComb = composeGrCombinations(
            state.couponLength,
            state.fixedArr
          );

          const updatedCheckedGroups = updateDataInCheckedGroups(
            checkedGr,
            Number(state.bettingAmount),
            state.odds,
            state.fixedArr,
            state.bettingConfig?.bonus,
            state.bettingConfig?.setting?.rank_bonus,
            state.bettingConfig?.setting["win_system"]
          );
          state.checkedGroups = updatedCheckedGroups;
          const checkedGroupsArray = Object.values(updatedCheckedGroups);
          const maxBonusValue = calculateSystemMaxBonus(
            checkedGroupsArray,
            state.bettingConfig?.setting["win_system"]
          );
          const maxTotBonusValue = calculateSystemTotBonus(
            checkedGroupsArray,
            state.bettingConfig?.setting["win_system"]
          );
          const maxWinValue = calculateSystemMaxWin(checkedGroupsArray);
          const maxTotWinValue = calculateSystemTotWin(checkedGroupsArray);
          const maxPotentialWinTotal = maxWinValue + maxBonusValue;
          const potentialWinValueTotal = maxTotBonusValue + maxTotWinValue;

          state.minBonus = calculateSystemMinBonus(
            checkedGroupsArray,
            state.bettingConfig?.setting["win_system"]
          );
          state.maxBonus = maxBonusValue;
          state.minPotentialWin = calculateSystemMinWin(
            checkedGroupsArray,
            state.couponType && state.bettingConfig?.setting["win_system"]
          );
          state.bettingConfig &&
            (state.maxPotentialWin =
              maxPotentialWinTotal > state.bettingConfig.setting["win_system"]
                ? state.bettingConfig.setting["win_system"]
                : maxPotentialWinTotal);
          state.bettingConfig &&
            (state.potentialWinValue =
              potentialWinValueTotal > state.bettingConfig.setting["win_system"]
                ? state.bettingConfig.setting["win_system"]
                : potentialWinValueTotal);
          state.isPotentialWinOverLimit =
            state.bettingConfig &&
            potentialWinValueTotal >
              state.bettingConfig.setting[state.couponType]
              ? true
              : false;
        } else {
          state.combination = calculateNrOfCombinations(state.coupon);
          const roundedAmount = (
            Number(state.bettingAmount) / state.combination
          )
            .toFixed(4)
            .toString();
          state.combinationAmount = roundedAmount;
          const cartesianProductOfOdds = [
            ...new CartesianProduct(...Object.values(state.coupon)),
          ];
          const prodOfOddCombinations = calculateMultiplicationOfOddComb(
            cartesianProductOfOdds
          );
          const calculatedMaxOdd = calculateArrayMaxMinValue(
            "max",
            prodOfOddCombinations
          );
          const calculatedMinOdd = calculateArrayMaxMinValue(
            "min",
            prodOfOddCombinations
          );
          state.maxOdd = calculatedMaxOdd;
          state.minOdd = calculatedMinOdd;
          const calculatedOddBonus = calculateOddBonus(
            cartesianProductOfOdds,
            state.bettingConfig?.bonus,
            state.bettingConfig?.setting?.rank_bonus
          );
          state.oddBonus = calculatedOddBonus;
          state.maxBonus =
            (calculateArrayMaxMinValue("max", state.oddBonus) *
              calculatedMaxOdd *
              Number(roundedAmount)) /
            100;
          state.minBonus =
            (calculateArrayMaxMinValue("min", state.oddBonus) *
              calculatedMinOdd *
              Number(roundedAmount)) /
            100;
          const calculatedPotWinArray = calculatePotentialWinArray(
            Number(roundedAmount),
            prodOfOddCombinations,
            calculatedOddBonus
          );
          state.potentialWinArray = calculatedPotWinArray;
          state.maxPotentialWin = calculateArrayMaxMinValue(
            "max",
            calculatedPotWinArray
          );
          state.minPotentialWin = calculateArrayMaxMinValue(
            "min",
            calculatedPotWinArray
          );
          const { isPotentialWinOverLimit, value } = calculatePotentialWinValue(
            calculatedPotWinArray,
            state.bettingConfig?.setting[state.couponType]
          );
          state.potentialWinValue = value;
          state.isPotentialWinOverLimit = isPotentialWinOverLimit;
        }
      }
    },
    getPlayedCouponFailed: (state: CouponState) => {
      state.isFetchingPlayedCoupon = false;
    },
    getBookedCouponRequested: (
      state: CouponState,
      action: PayloadAction<any>
    ) => {
      state.isFetching = true;
      action;
    },
    getBookedCouponSucceded: (
      state: CouponState,
      action: PayloadAction<PlayedCoupon>
    ) => {
      state.isFetching = false;
      const composedOdds = composeCouponOdds(action.payload);
      if (composedOdds.length > 0) {
        state.coupon = composeCouponData(action.payload);
        state.odds = composedOdds;
        state.bettingAmount = action.payload.bet_amount.toFixed(2).toString();
        state.couponLength = action.payload.odds.length;

        /** ********* set limits *************/
        state.couponType = calculateCouponType(
          state.odds,
          state.couponLength,
          Object.keys(state.coupon).length,
          action.payload.systems.length > 0 ? 1 : 0
        );
        /**********************************/

        if (action.payload.systems.length > 0) {
          state.couponTypeTab = 1;
          /**system calculations */
          let checkedGr = {};
          action.payload.systems.map((group) => {
            checkedGr = {
              ...checkedGr,
              [group.group]: {
                combination: group.comb,
                combinationAmount: group.bet_amount,
              },
            };
          });
          state.fixedArr = action.payload.fixed;
          state.groupComb = composeGrCombinations(
            state.couponLength,
            state.fixedArr
          );

          const updatedCheckedGroups = updateDataInCheckedGroups(
            checkedGr,
            Number(state.bettingAmount),
            state.odds,
            state.fixedArr,
            state.bettingConfig?.bonus,
            state.bettingConfig?.setting?.rank_bonus,
            state.bettingConfig?.setting["win_system"]
          );
          state.checkedGroups = updatedCheckedGroups;
          const checkedGroupsArray = Object.values(updatedCheckedGroups);
          const maxBonusValue = calculateSystemMaxBonus(
            checkedGroupsArray,
            state.bettingConfig?.setting["win_system"]
          );
          const maxTotBonusValue = calculateSystemTotBonus(
            checkedGroupsArray,
            state.bettingConfig?.setting["win_system"]
          );
          const maxWinValue = calculateSystemMaxWin(checkedGroupsArray);
          const maxTotWinValue = calculateSystemTotWin(checkedGroupsArray);
          const maxPotentialWinTotal = maxWinValue + maxBonusValue;
          const potentialWinValueTotal = maxTotBonusValue + maxTotWinValue;

          state.minBonus = calculateSystemMinBonus(
            checkedGroupsArray,
            state.bettingConfig?.setting["win_system"]
          );
          state.maxBonus = maxBonusValue;
          state.minPotentialWin = calculateSystemMinWin(
            checkedGroupsArray,
            state.couponType && state.bettingConfig?.setting["win_system"]
          );
          state.bettingConfig &&
            (state.maxPotentialWin =
              maxPotentialWinTotal > state.bettingConfig.setting["win_system"]
                ? state.bettingConfig.setting["win_system"]
                : maxPotentialWinTotal);
          state.bettingConfig &&
            (state.potentialWinValue =
              potentialWinValueTotal > state.bettingConfig.setting["win_system"]
                ? state.bettingConfig.setting["win_system"]
                : potentialWinValueTotal);
          state.isPotentialWinOverLimit =
            state.bettingConfig &&
            potentialWinValueTotal >
              state.bettingConfig.setting[state.couponType]
              ? true
              : false;
        } else {
          state.combination = calculateNrOfCombinations(state.coupon);
          const roundedAmount = (
            Number(state.bettingAmount) / state.combination
          )
            .toFixed(4)
            .toString();
          state.combinationAmount = roundedAmount;
          const cartesianProductOfOdds = [
            ...new CartesianProduct(...Object.values(state.coupon)),
          ];
          const prodOfOddCombinations = calculateMultiplicationOfOddComb(
            cartesianProductOfOdds
          );
          const calculatedMaxOdd = calculateArrayMaxMinValue(
            "max",
            prodOfOddCombinations
          );
          const calculatedMinOdd = calculateArrayMaxMinValue(
            "min",
            prodOfOddCombinations
          );
          state.maxOdd = calculatedMaxOdd;
          state.minOdd = calculatedMinOdd;
          const calculatedOddBonus = calculateOddBonus(
            cartesianProductOfOdds,
            state.bettingConfig?.bonus,
            state.bettingConfig?.setting?.rank_bonus
          );
          state.oddBonus = calculatedOddBonus;
          state.maxBonus =
            (calculateArrayMaxMinValue("max", state.oddBonus) *
              calculatedMaxOdd *
              Number(roundedAmount)) /
            100;
          state.minBonus =
            (calculateArrayMaxMinValue("min", state.oddBonus) *
              calculatedMinOdd *
              Number(roundedAmount)) /
            100;
          const calculatedPotWinArray = calculatePotentialWinArray(
            Number(roundedAmount),
            prodOfOddCombinations,
            calculatedOddBonus
          );
          state.potentialWinArray = calculatedPotWinArray;
          state.maxPotentialWin = calculateArrayMaxMinValue(
            "max",
            calculatedPotWinArray
          );
          state.minPotentialWin = calculateArrayMaxMinValue(
            "min",
            calculatedPotWinArray
          );
          const { isPotentialWinOverLimit, value } = calculatePotentialWinValue(
            calculatedPotWinArray,
            state.bettingConfig?.setting[state.couponType]
          );
          state.potentialWinValue = value;
          state.isPotentialWinOverLimit = isPotentialWinOverLimit;
        }
      } else {
        state.closedEventsMsg = "closedEventsMsg";
      }
    },
    getBookedCouponFailed: (state: CouponState) => {
      state.isFetching = false;
    },
    checkCouponStateRequested: (
      state: CouponState,
      action: PayloadAction<string>
    ) => {
      state.isFetching = true;
      action;
    },
    checkCouponStateSucceded: (
      state: CouponState,
      action: PayloadAction<PlayedCouponState>
    ) => {
      state.playedCouponsStates &&
        (state.playedCouponsStates[action.payload.id] = action.payload);
    },
    checkCouponStateFailed: (state: CouponState) => {
      state.isFetching = false;
    },
    confirmCouponRequested: (
      state: CouponState,
      action: PayloadAction<string>
    ) => {
      state.isFetching = true;
      action;
    },
    confirmCouponSucceded: (
      state: CouponState,
      action: PayloadAction<PlayedCouponState>
    ) => {
      state.isFetching = false;
      state.isPlayedCouponReceiptVisible = false;
      state.playedCoupon = undefined;
      state.playedCouponsStates &&
        (state.playedCouponsStates[action.payload.id] = action.payload);
    },
    confirmCouponFailed: (state: CouponState) => {
      state.isFetching = false;
      state.isPlayedCouponReceiptVisible = false;
      state.playedCoupon = undefined;
    },
    refuseCouponRequested: (
      state: CouponState,
      action: PayloadAction<string>
    ) => {
      state.isFetching = true;
      action;
    },
    refuseCounponSucceded: (
      state: CouponState,
      action: PayloadAction<PlayedCouponState>
    ) => {
      state.isFetching = false;
      state.isPlayedCouponReceiptVisible = false;
      state.playedCoupon = undefined;
      state.playedCouponsStates &&
        (state.playedCouponsStates[action.payload.id] = action.payload);
    },
    refuseCounponFailed: (state: CouponState) => {
      state.isFetching = false;
      state.isPlayedCouponReceiptVisible = false;
      state.playedCoupon = undefined;
    },
    getMyBetsRequested: (state: CouponState) => {
      state.isFetching = true;
    },
    getMyBetsSucceded: (
      state: CouponState,
      action: PayloadAction<MyBetsResponse | undefined>
    ) => {
      state.isFetching = false;
      action.payload
        ? (state.playedBets = action.payload.played)
        : (state.playedBets = undefined);
      action.payload && (state.inAcceptanceBets = action.payload.acceptance);

      !state.playedCouponsStates &&
        action.payload &&
        action.payload.acceptance.map(
          (el) =>
            (state.playedCouponsStates = {
              ...state.playedCouponsStates,
              [el.id]: el,
            })
        );
    },
    getMyBetsFailed: (state: CouponState) => {
      state.isFetching = false;
    },
    getEventForQuickBetRequested: (
      state: CouponState,
      action: PayloadAction<number>
    ) => {
      state.isFetching = true;
      action;
    },
    getEventForQuickBetSucceded: (
      state: CouponState,
      action: PayloadAction<any>
    ) => {
      state.isFetching = false;
      state.quickbetOdds = action.payload;
    },
    getEventForQuickBetFailed: (state: CouponState) => {
      state.isFetching = false;
    },
    showPlayedCouponReceipt: (
      state: CouponState,
      action: PayloadAction<boolean>
    ) => {
      state.isPlayedCouponReceiptVisible = action.payload;
    },
    showPlayedCouponDetails: (
      state: CouponState,
      action: PayloadAction<boolean>
    ) => {
      state.isPlayedCouponDetailVisible = action.payload;
    },
    hideBookedCouponDialog: (state: CouponState) => {
      state.isPlayedCouponReceiptVisible = false;
      state.playedCoupon = undefined;
    },
    triggerBackdropVisibility: (
      state: CouponState,
      action: PayloadAction<boolean>
    ) => {
      state.backdropVisibility = action.payload;
    },
    setProceedState: (state: CouponState, action: PayloadAction<boolean>) => {
      state.proceedState = action.payload;
    },
    setBookState: (state: CouponState, action: PayloadAction<boolean>) => {
      state.bookState = action.payload;
    },
    setCouponMobile: (state: CouponState) => {
      state.couponMobile = !state.couponMobile;
    },
    saveBetCodes: (
      state: CouponState,
      action: PayloadAction<Record<string, string>[]>
    ) => {
      state.betCodesData = action.payload;
    },
    saveCouponId: (state: CouponState, action: PayloadAction<string>) => {
      state.couponId = action.payload;
    },
    savePayedCouponId: (state: CouponState, action: PayloadAction<string>) => {
      state.payedCouponId = action.payload;
    },
    saveCancelCouponId: (state: CouponState, action: PayloadAction<string>) => {
      state.couponId = action.payload;
    },
    cancelCouponRequested: (
      state: CouponState,
      action: PayloadAction<string>
    ) => {
      state.isFetchingCancelCoupon = true;
      action;
    },
    cancelCouponSucceeded: (
      state: CouponState,
      action: PayloadAction<PlayedCouponState>
    ) => {
      state.isFetchingCancelCoupon = false;
      state.playedCouponState = action.payload;
      action.payload
        ? (state.playedCouponsStates = {
            [action.payload.id]: action.payload,
            ...state.playedCouponsStates,
          })
        : (state.playedCouponsStates = undefined);
    },
    cancelCouponFailed: (state: CouponState) => {
      state.isFetchingCancelCoupon = false;
    },
    payedCouponRequested: (state: CouponState, action: PayloadAction<any>) => {
      state.isFetchingPayedCoupon = true;
      action;
    },
    payedCouponSucceded: (
      state: CouponState,
      action: PayloadAction<PlayedCouponState>
    ) => {
      state.isFetchingCancelCoupon = false;
      state.playedCouponState = action.payload;
      action.payload
        ? (state.playedCouponsStates = {
            [action.payload.id]: action.payload,
            ...state.playedCouponsStates,
          })
        : (state.playedCouponsStates = undefined);
    },
    payedCouponFailed: (state: CouponState) => {
      state.isFetchingPayedCoupon = false;
    },

    toggleMobileCouponDrawer: (
      state: CouponState,
      action: PayloadAction<boolean>
    ) => {
      state.isCouponMobileVisible = action.payload;
    },
    clearAmount: (state: CouponState) => {
      state.bettingAmount = "";
    },
    clearBookResponse: (state: CouponState) => {
      state.isBooked = false;
      state.playedCouponState = undefined;
    },
    clearPlayResponse: (state: CouponState) => {
      state.isPlayed = false;
      state.playedCouponState = undefined;
    },
    clearCouponResponse: (state: CouponState) => {
      state.playedCoupon = undefined;
    },
    clearCouponsState: (state: CouponState) => {
      state.playedCouponsStates = undefined;
      state.playedBets = undefined;
    },
    clearCouponState: (state: CouponState) => {
      state.coupon = {};
      state.odds = [];
      state.couponLength = 0;
      state.maxOdd = undefined;
      state.minOdd = undefined;
      state.maxBonus = undefined;
      state.minBonus = undefined;
      state.maxPotentialWin = undefined;
      state.minPotentialWin = undefined;
      state.bettingAmount = "";
      state.combinationAmount = "";
      state.potentialWinValue = undefined;
      state.combination = undefined;
      state.potentialWinArray = undefined;
      state.oddBonus = undefined;
      state.couponType = undefined;
      state.fixedArr = [];
      state.checkedGroups = {};
      state.groupComb = {};
      state.couponTypeTab = 0;
      state.isPotentialWinOverLimit = false;
    },
    clearNoCouponAvailableMsg: (state: CouponState) => {
      state.closedEventsMsg = undefined;
    },
    saveCombinationFilter: (
      state: CouponState,
      action: PayloadAction<string>
    ) => {
      state.combinationFilter = action.payload;
    },
    getCashoutRequested: (
      state: CouponState,
      action: PayloadAction<string>
    ) => {
      state.isFetchingCashout = true;

      action;
    },
    getCashoutSucceded: (
      state: CouponState,
      action: PayloadAction<CashoutData>
    ) => {
      state.isFetchingCashout = false;
      state.cashout = action.payload;
      state.cashoutState = "verify";
    },
    getCashoutFailed: (state: CouponState) => {
      state.isFetchingCashout = false;
    },
    getCashoutConfirmRequested: (
      state: CouponState,
      action: PayloadAction<string>
    ) => {
      state.isFetchingCashout = true;
      action;
    },
    getCashoutConfirmSucceded: (
      state: CouponState,
      action: PayloadAction<CashoutData>
    ) => {
      state.isFetchingCashout = false;
      state.cashout = action.payload;
      state.cashoutState = "confirm";
    },
    getCashoutConfirmFailed: (state: CouponState) => {
      state.isFetchingCashout = false;
    },
    clearCashoutData: (state: CouponState) => {
      state.cashout = undefined;
      state.cashoutState = undefined;
    },
  },
});

export const {
  saveFixedOdds,
  updateAmounts,
  saveCheckedGroup,
  saveBettingAmount,
  saveCombinationAmount,
  addOddInCoupon,
  removeOddFromCoupon,
  updateCalculationsForOddChange,
  clearAmount,
  clearCouponState,
  clearBookResponse,
  clearPlayResponse,
  saveCouponRequestPayload,
  playCouponRequested,
  playCouponSucceded,
  playCouponFailed,
  bookCouponRequested,
  bookCouponSucceded,
  bookCouponFailed,
  showPlayedCouponReceipt,
  showPlayedCouponDetails,
  hideBookedCouponDialog,
  getPlayedCouponRequested,
  getPlayedCouponSucceded,
  getPlayedCouponFailed,
  removeEventFromCoupon,
  setProceedState,
  setBookState,
  saveCouponType,
  saveCouponTypeTabValue,
  clearCouponResponse,
  clearCouponsState,
  getBookedCouponRequested,
  getBookedCouponSucceded,
  getBookedCouponFailed,
  updateCombinations,
  setCouponMobile,
  getMyBetsRequested,
  getMyBetsSucceded,
  getMyBetsFailed,
  printPlayedCouponRequested,
  printPlayedCouponSucceded,
  printPlayedCouponFailed,
  confirmCouponRequested,
  confirmCouponSucceded,
  confirmCouponFailed,
  refuseCouponRequested,
  refuseCounponSucceded,
  refuseCounponFailed,
  saveCouponTab,
  triggerBackdropVisibility,
  checkCouponStateRequested,
  checkCouponStateSucceded,
  checkCouponStateFailed,
  updatePlayedCuponStates,
  getEventForQuickBetRequested,
  getEventForQuickBetSucceded,
  getEventForQuickBetFailed,
  verifyPlayedCouponRequested,
  verifyPlayedCouponSucceded,
  verifyPlayedCouponFailed,
  saveBetCodes,
  saveCancelCouponId,
  cancelCouponRequested,
  cancelCouponSucceeded,
  cancelCouponFailed,
  toggleMobileCouponDrawer,
  saveCouponId,
  savePayedCouponId,
  payedCouponFailed,
  payedCouponRequested,
  payedCouponSucceded,
  getOddsUpdatesRequested,
  getOddsUpdatesSucceded,
  getOddsUpdatesFailed,
  clearNoCouponAvailableMsg,
  saveCombinationFilter,
  getBettingConfigRequested,
  getBettingConfigSucceded,
  getBettingConfigFailed,
  getAuthBettingConfigRequested,
  getAuthBettingConfigSucceded,
  getAuthBettingConfigFailed,
  getCashoutRequested,
  getCashoutFailed,
  getCashoutSucceded,
  getCashoutConfirmRequested,
  getCashoutConfirmSucceded,
  getCashoutConfirmFailed,
  clearCashoutData,
  saveBettingConfigSucceded,
} = couponSlice.actions;

export default couponSlice.reducer;
