
import React, { ReactElement, useEffect } from "react";
import { Translate } from "react-redux-i18n";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Box, IconButton, Typography } from "@mui/material";

import CouponVerify from "../CouponVerify";
import { checkWalletPermissions } from "../../../services/utils";
import {
  MyBet, getCashoutRequested, getMyBetsRequested,
  getPlayedCouponRequested,
  saveCancelCouponId,
  saveCouponTab,
  showPlayedCouponReceipt
} from "../../../redux/coupon/couponSlice";
import { openDialog } from "../../../redux/dialog/dialogSlice";
import FontAwesomeIcon from "../../shared/FontAwesomeIcon";
import styles from "./styles.module.scss";


function MyBets(): ReactElement {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(state => state.auth.isAuth);
  const permissions = useAppSelector(state => state.auth.permissions);
  const playedBets = useAppSelector(state => state.coupon.playedBets);
  const inAcceptanceBets = useAppSelector(state => state.coupon.inAcceptanceBets);
  const isCouponInAcceptance = inAcceptanceBets?.filter(bet => bet.acceptance)

  useEffect(() => {
    if (
      isAuth &&
      permissions &&
      (checkWalletPermissions(permissions, "game.bet") ||
        checkWalletPermissions(permissions, "game.betassist"))) {
      dispatch(getMyBetsRequested());
    }
  }, [permissions, isAuth])

  useEffect(() => {
    if (
      isAuth &&
      permissions &&
      (checkWalletPermissions(permissions, "game.bet") ||
        checkWalletPermissions(permissions, "game.betassist"))) {
      if (
        isCouponInAcceptance &&
        isCouponInAcceptance.length > 0) {
        const getInterval = setInterval(() => {
          dispatch(getMyBetsRequested());
        }, 2000);
        return () => {
          clearInterval(getInterval)
        }
      }
    }
  }, [isCouponInAcceptance])

  return (
    <Box >
      <CouponVerify caller="mybets" />
      {inAcceptanceBets &&
        inAcceptanceBets.length !== 0 &&
        <>
          <Typography className={styles.titleFont}>
            <Translate value="coupon.inAcceptanceBets" />
          </Typography>
          {
            inAcceptanceBets &&
            inAcceptanceBets
              .map(
                bet =>
                  <Bet
                    key={bet.id}
                    bet={bet}
                    caller="inAcceptanceBets"
                  />
              )}
        </>
      }
      <Typography className={styles.titleFont}>
        <Translate value="coupon.playedBets" />
      </Typography>
      {
        playedBets &&
        playedBets
          .map(
            bet =>
              <Bet
                key={bet.id}
                bet={bet}
                caller="playedBets"
              />
          )}
    </Box>

  );
}

export default MyBets;
interface IProps {
  bet: MyBet,
  caller: string
}
function Bet({ bet, caller }: IProps): ReactElement {
  const dispatch = useAppDispatch();
  const permissions = useAppSelector(state => state.auth.permissions)
  const { acceptance, played, user_confirm, running, winner } = bet;

  const handleAction = (couponId: string) => {
    if ((acceptance && user_confirm) || (!acceptance && played)) {
      dispatch(showPlayedCouponReceipt(true))
      dispatch(
        getPlayedCouponRequested({
          isRepeated: false,
          couponId: couponId
        }))
    }
  }

  const handleReplay = (couponId: string, replay: boolean) => {
    if (replay) {
      dispatch(
        getPlayedCouponRequested({
          isRepeated: true,
          couponId: couponId
        }))
      dispatch(saveCouponTab(0));
    }
  }

  const handleCancelCoupon = (couponId: string) => {
    dispatch(openDialog({ type: "cancel" }))
    dispatch(saveCancelCouponId(couponId))
  }

  const handleCashoutClick = (couponId: string) => {
    dispatch(getCashoutRequested(couponId))
    dispatch(openDialog({ type: "cashout", payload: couponId }))
  }

  return (
    <Box className={styles.betContainer} >
      <Box className={styles.betContainerItem} >
        <FontAwesomeIcon
          type="fal"
          name="circle-play"
          margin={1}
          color={
            caller === "playedBets" ?
              bet.void === true ? "white" :
                !played ? "blue" :
                  running ? "yellow"
                    : !running && winner ? "green"
                      : !running && !winner ? "red"
                        : "white"
              :
              acceptance || user_confirm ? "yellow"
                : "red"
          }
        />
        <Typography className={styles.betFont}>
          {bet.serial}
        </Typography>
      </Box>
      <Box className={styles.betContainerItem}>
        {bet.cashout &&
          <Box onClick={() => handleCashoutClick(bet.id)} className={styles.cashoutButton}>
            <FontAwesomeIcon name="money-bill" type="fal" />
          </Box>
        }
        {
          permissions &&
          checkWalletPermissions(permissions, "game.betassist")
          &&
          bet.cancellable &&
          <IconButton
            onClick={() => handleCancelCoupon(bet.id)}
            className={styles.cancelCouponIcon}
          >
            <FontAwesomeIcon type="fas" name="trash" />
          </IconButton>
        }
        <Box
          onClick={() => handleReplay(bet.id, bet.replay)}
          className={`${bet.replay ? styles.iconBox : styles.iconBoxNoCursor}`}>
          {bet.replay &&
            <FontAwesomeIcon
              type="fal"
              name={bet.replay ? "rotate-right" : ""}
              margin={0.5}
              width="15px"
            />
          }
        </Box>
        <Box
          onClick={() => handleAction(bet.id)}
          className={`${(acceptance && !played && !user_confirm) ?
            styles.iconBoxNoCursor
            :
            (acceptance && !played && user_confirm) ? styles.iconBox
              :
              (!acceptance && bet.played) ? styles.iconBox
                : (!acceptance && !played) ? styles.iconBox
                  :
                  styles.iconBoxNoCursor}`}>
          <FontAwesomeIcon
            type="fal"
            name={
              acceptance && !played && !user_confirm ? "hourglass"
                : acceptance && !played && user_confirm ? "comments-question-check"
                  : !acceptance && bet.played ? "magnifying-glass"
                    : !acceptance && !played ? ""
                      : ""
            }
            margin={0.5}
            width="15px"
          />
        </Box>
      </Box>
    </Box>
  )
}