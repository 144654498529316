import React, { ReactElement, useEffect, useState } from "react";
import { Translate } from "react-redux-i18n";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Table, TableBody, TableHead, TableCell, TableRow, TableContainer, } from "@mui/material";
import { Page, Document } from '@react-pdf/renderer';
import Barcode from 'react-barcode';
import FontAwesomeIcon from "../../shared/FontAwesomeIcon";

import {
  PlayedCouponSystems,
  PlayedCouponState,
  PlayedCoupon,
  PlayedCouponOddContent
} from "../../../redux/coupon/couponSlice";
import { convertBeginToDate, convertDateToEurpoean, convertOddValue } from "../../../services/utils";
import { NewEvent } from "../../shared/CouponContent";
import styles from './styles.module.scss'
import { useAppSelector } from "../../../redux/hooks";

interface IProps {
  isBooked: boolean;
  playedCouponState: PlayedCouponState | undefined;
  playedCoupon: PlayedCoupon | undefined;
  counter?: number;
  handleCouponRefuse: (id: string) => void;
  handleCouponConfirm: (id: string) => void;
  skinLogo?: string;
  composedReceiptData?: NewEvent[];
}

export default function Receipt({
  skinLogo,
  isBooked,
  playedCouponState,
  playedCoupon,
  counter,
  handleCouponRefuse,
  handleCouponConfirm,
  composedReceiptData,
}: IProps): ReactElement {
  const isUserAuth = useAppSelector(state => state.auth.isAuth)
  const couponLogo = process.env.REACT_APP_COUPON_LOGO as string;
  const dividerState = useAppSelector(state => state.coupon.playedCoupon?.currency.divider)
  const decimalState = useAppSelector(state => state.coupon.playedCoupon?.currency.decimal)
  const currency = useAppSelector(state => state.coupon.playedCoupon?.currency.symbol)
  const skinCurrency = currency ? currency : "€"

  const [divider, setDivider] = useState(1);
  const [decimal, setDecimal] = useState(2)

  useEffect(() => {
    if (isUserAuth) {
      dividerState && setDivider(dividerState)
      decimalState && setDecimal(decimalState)
    } else {
      setDivider(1)
      setDecimal(2)
    }
  }, [isUserAuth])

  return (
    <Document >
      <Page wrap={false} >
        {
          isBooked ?
            <Box className={styles.mainContainer}>
              <Box className={styles.barcodeContainerNoReceipt}>
                {playedCouponState &&
                  <Barcode
                    value={playedCouponState.serial}
                    displayValue={false}
                  />}
                <Typography sx={{ color: "#000000", fontSize: 15, fontWeight: 700 }}>
                  {playedCouponState?.serial}
                </Typography>
              </Box>
            </Box>
            :
            <Box className={styles.mainContainer} >
              {
                playedCoupon?.printed &&
                <Box className={styles.watermark}>
                  <Typography
                    className={styles.watermarkText}
                    sx={{ color: "#808080", fontSize: 85 }}>
                    <Translate value="coupon.copy" />
                  </Typography>
                </Box>
              }
              {
                playedCoupon &&
                <>
                  {(couponLogo == "true") ?
                    <img src={skinLogo} className={styles.receiptLogo} />
                    : ""}
                  <Box
                    className={styles.receiptHeader}
                    sx={{
                      border: "0.1px solid #000000"
                    }}>
                    <Box
                      className={styles.receiptHeaderRow}
                      sx={{
                        borderBottom: "0.1px solid #000000"
                      }}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 11,
                          fontWeight: 700
                        }}>
                        <Translate value="coupon.couponCode" />
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 11,
                          fontWeight: 700
                        }}>
                        {playedCoupon.id}
                      </Typography>
                    </Box>
                    <Box className={styles.receiptHeaderRow}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 11,
                          fontWeight: 700
                        }}>
                        <Translate value="coupon.username" />
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 11,
                          fontWeight: 700
                        }}>
                        {playedCoupon.username}
                      </Typography>
                    </Box>
                    <Box className={styles.receiptHeaderRow}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 11,
                          fontWeight: 700
                        }}>
                        <Translate value="coupon.cod" />
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 11,
                          fontWeight: 700
                        }}>
                        {playedCoupon.parent_id}
                      </Typography>
                    </Box>
                    <Box className={styles.receiptHeaderRow}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 12,
                          fontWeight: 700
                        }}>
                        <Translate value="coupon.date" />
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 12,
                          fontWeight: 700
                        }}>
                        {convertDateToEurpoean(playedCoupon.created_at.slice(0, 10))} - {playedCoupon.created_at.slice(11, 19)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className={styles.eventsContainer}>
                    <Box
                      className={styles.eventsTitle}
                      sx={{ border: "0.1px solid #000000" }}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 12,
                          fontWeight: 700,
                          textTransform: "uppercase"
                        }}>
                        <Translate value={`coupon.${playedCoupon.coupon_type.label}`} />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: "0.1px solid #000000",
                        borderLeft: "0.1px solid #000000",
                        borderRight: "0.1px solid #000000",
                        padding: 1
                      }}>
                      {composedReceiptData &&
                        composedReceiptData.map((event: NewEvent) => {
                          const fullTime = convertBeginToDate(event.begin)
                          const date = fullTime.split(" ")[0]
                          const time = fullTime.split(" ")[1]
                          return (
                            <Box
                              key={event.id}
                              sx={{ borderBottom: "0.1px solid #000000", }}>
                              <Box className={styles.eventHeader}>
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontSize: 9,
                                    fontWeight: 600,
                                    width: "63%"
                                  }}>
                                  {event.sport} - {event.category} - {event.tournament}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontSize: 9,
                                    fontWeight: 600,
                                    width: "37%"
                                  }}>
                                  {date} - {time}
                                </Typography>
                              </Box>
                              <Box className={styles.eventContent}>
                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontSize: 11,
                                    fontWeight: 600
                                  }}>
                                  {event.short} - {event.label}
                                </Typography>

                                <Typography
                                  sx={{
                                    color: "#000000",
                                    fontSize: 11,
                                    fontWeight: 700
                                  }}>
                                  {event.fix ? "Fix" : null}
                                </Typography>
                              </Box>

                              {event.odds &&
                                event
                                  .odds
                                  .map((el: PlayedCouponOddContent) =>
                                    <Box
                                      key={el.unique}
                                      className={styles.eventMarket}>
                                      <Typography
                                        sx={{
                                          color: "#000000",
                                          fontSize: 11,
                                          fontWeight: 700,
                                          mr: 1
                                        }}>
                                        {el.market.label} ({el.odd.label}) {el.odd?.extra ? el.odd.extra : el.odd?.spread}
                                      </Typography>

                                      {playedCoupon.user_confirm && el.odd.value_changed ?
                                        <Box className={styles.oddRequiredBox}>
                                          <Typography sx={{ color: "#ff0000", fontSize: 12 }}>
                                            {convertOddValue(el.odd.value_required)}
                                          </Typography>
                                          <Typography sx={{ color: "#008000", fontSize: 12 }}>
                                            {convertOddValue(el.odd.value)}
                                          </Typography>
                                        </Box>
                                        :
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                          <Box className={
                                            //when its running = yellow , void = white , winner green , else red
                                            el.odd.running
                                              ? styles.stateYellow
                                              : el.odd.winner
                                                ? styles.stateGreen
                                                : el.odd.void
                                                  ? styles.stateWhite
                                                  : styles.stateRed
                                          }
                                            component="span">
                                            <FontAwesomeIcon type="fas" name="circle" />
                                          </Box>
                                          <Typography
                                            sx={{
                                              color: "#000000",
                                              fontSize: 11,
                                              fontWeight: 700,
                                              ml: 1,

                                            }}>
                                            {convertOddValue(el.value)}
                                          </Typography>

                                        </Box>
                                      }
                                    </Box>
                                  )}

                            </Box >
                          )
                        })}
                    </Box >
                  </Box>

                  {
                    playedCoupon.systems.length > 0 &&
                    <TableContainer >
                      <Table
                        className={styles.systemContainer}
                        size="small"
                        sx={{
                          borderLeft: "0.1px solid #000000",
                          borderTop: "0.1px solid #000000"
                        }}>
                        <TableHead className={styles.systemHeaders}>
                          <TableRow>
                            <TableCell
                              sx={{
                                color: "#000000",
                                fontSize: 11,
                                fontWeight: 600
                              }}
                              className={styles.systemHeader}>
                              <Translate value="coupon.type" />
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000000",
                                fontSize: 10,
                                fontWeight: 600
                              }}
                              className={styles.systemHeader}>
                              N.COL X {skinCurrency}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: 0,
                                color: "#000000",
                                fontSize: 11,
                                fontWeight: 600
                              }}
                              className={styles.systemHeader}>
                              <Translate value="coupon.total" />
                            </TableCell>
                            <TableCell
                              className={styles.systemHeader}
                              sx={{
                                color: "#000000",
                                fontSize: 11,
                                fontWeight: 600
                              }}
                            >
                              <Translate value="coupon.odd" />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody >
                          {
                            playedCoupon
                              .systems
                              .map((system: PlayedCouponSystems) => {
                                return (
                                  <TableRow key={system.group} >
                                    <TableCell
                                      sx={{
                                        color: "#000000",
                                        padding: 0,
                                        fontSize: 11,
                                        textAlign: "center",
                                        fontWeight: 600
                                      }}>
                                      {system.group}/{playedCoupon.events_count}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000000",
                                        padding: 0,
                                        fontSize: 11,
                                        textAlign: "center",
                                        fontWeight: 600
                                      }}>
                                      {system.comb}x{((system.amount / system.comb) / divider).toFixed(4)}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000000",
                                        padding: 0,
                                        fontSize: 11,
                                        textAlign: "center",
                                        fontWeight: 600
                                      }}>
                                      {(system.amount / divider).toFixed(decimal)}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        color: "#000000",
                                        padding: 0,
                                        fontSize: 11,
                                        textAlign: "center",
                                        fontWeight: 600
                                      }}>
                                      <Box className={styles.systemCellLast}>
                                        <Typography
                                          sx={{
                                            color: "#000000",
                                            fontSize: 11,
                                            textAlign: "center",
                                            fontWeight: 600
                                          }}>
                                          Min. {(system.win_min / divider).toFixed(decimal)}{skinCurrency}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            color: "#000000",
                                            fontSize: 11,
                                            textAlign: "center",
                                            fontWeight: 600
                                          }}>
                                          Max. {(system.win_max / divider).toFixed(decimal)}{skinCurrency}
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                  <Box
                    className={styles.betContainer}
                    sx={{ border: "0.1px solid #000000 " }} >
                    <Typography
                      sx={{
                        color: "#000000",
                        fontSize: 16,
                        fontWeight: 700,
                        textTransform: "uppercase"
                      }}>
                      <Translate value="coupon.bet" />
                    </Typography>
                    {playedCoupon.coupon_type.id === 4 && composedReceiptData &&
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 700,
                          textTransform: "uppercase",
                          display: "flex",
                          alignItems: "center",
                          gap: "5px"
                        }}>
                        {((playedCoupon.bet_amount / (playedCoupon.columns_count)) / divider).toFixed(decimal)}{skinCurrency}
                        <span style={{ textTransform: "lowercase" }}>x</span>
                        {playedCoupon.columns_count}
                      </Typography>
                    }
                    {playedCoupon.user_confirm && playedCoupon.bet_changed ?
                      <Box className={styles.betRequriedBox}>
                        <Typography
                          sx={{
                            color: "#ff0000",
                            fontSize: 16,
                            fontWeight: 700,
                            textTransform: "uppercase"
                          }}>
                          {(playedCoupon.bet_required / divider).toFixed(decimal)}{skinCurrency}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#008000",
                            fontSize: 16,
                            fontWeight: 700,
                            textTransform: "uppercase"
                          }}>
                          {(playedCoupon.bet_amount / divider).toFixed(decimal)}{skinCurrency}
                        </Typography>
                      </Box>
                      :
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 700,
                          textTransform: "uppercase"
                        }}>
                        {(playedCoupon.bet_amount / divider).toFixed(2)}{skinCurrency}
                      </Typography>
                    }
                  </Box>
                  {
                    playedCoupon?.coupon_type.id === 1 &&
                    <Box
                      className={styles.betContainer}
                      sx={{
                        mb: 1,
                        border: "0.1px solid #000000 "
                      }}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 700,
                          textTransform: "uppercase"
                        }}>
                        <Translate value="coupon.totalOdd" />
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 700,
                          textTransform: "uppercase"
                        }}>
                        {playedCoupon?.odds_max.toFixed(2)}
                      </Typography>
                    </Box>
                  }
                  {
                    playedCoupon?.coupon_type.id === 2 &&
                    <Box className={styles.betContainer}
                      sx={{
                        mb: 1,
                        border: "0.1px solid #000000 "
                      }}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 700,
                          textTransform: "uppercase"
                        }}>
                        <Translate value="coupon.totalOdd" />
                      </Typography>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 700,
                          textTransform: "uppercase"
                        }}>
                        {playedCoupon?.odds_max.toFixed(2)}
                      </Typography>
                    </Box>
                  }

                  {
                    playedCoupon?.coupon_type.id === 4 &&
                    <Box
                      className={styles.oddContainer}
                      sx={{
                        borderTop: "0.1px solid #000000",
                        borderLeft: "0.1px solid #000000",
                        borderRight: "0.1px solid #000000"
                      }}>
                      <Typography
                        sx={{
                          textAlign: "center",
                          borderBottom: "0.1px solid #000000",
                          color: "#000000",
                          fontSize: 16,
                          fontWeight: 700,
                          textTransform: "uppercase"
                        }}>
                        <Translate value="couponDetail.detailsOdd" />
                      </Typography>
                      <Box className={styles.oddMinMax}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: 700,
                          }}>
                          Min.{convertOddValue(playedCoupon.odds_min)}</Typography>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: 700,
                          }}>
                          Max.{convertOddValue(playedCoupon.odds_max)}</Typography>
                      </Box>

                    </Box>
                  }

                  <Box className={styles.winContainer}>
                    {playedCoupon?.coupon_type.id === 1 && playedCoupon.bonus_max > 0 &&
                      <Box
                        sx={{
                          borderTop: "0.1px solid #000000",
                          borderLeft: "0.1px solid #000000",
                          borderRight: "0.1px solid #000000"
                        }}>
                        <Typography
                          sx={{
                            borderBottom: "0.1px solid #000000",
                            color: "#000000",
                            fontSize: 15,
                            fontWeight: 700,
                            textTransform: "uppercase", textAlign: "center"
                          }}>
                          BONUS
                        </Typography>
                        <Box className={styles.bonus}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: 17,
                              fontWeight: 700
                            }}>
                            {divider && (playedCoupon.bonus_max / divider).toFixed(decimal)}{skinCurrency}
                          </Typography>
                        </Box>
                      </Box>
                    }
                    {playedCoupon?.coupon_type.id !== 1 &&
                      <>
                        {/*BONUS CONTAINER*/}
                        <Box
                          sx={{
                            borderTop: "0.1px solid #000000",
                            borderLeft: "0.1px solid #000000",
                            borderRight: "0.1px solid #000000"
                          }}>
                          <Typography
                            sx={{
                              borderBottom: "0.1px solid #000000",
                              color: "#000000",
                              fontSize: 15,
                              fontWeight: 700,
                              textTransform: "uppercase", textAlign: "center"
                            }}>
                            BONUS
                          </Typography>
                          {playedCoupon?.coupon_type.id === 2 &&
                            <Box className={styles.bonus}>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: 17,
                                  fontWeight: 700
                                }}>
                                {(playedCoupon.bonus_max / divider).toFixed(decimal)}{skinCurrency}
                              </Typography>
                            </Box>
                          }
                          {playedCoupon?.coupon_type.id !== 1 && playedCoupon?.coupon_type.id !== 2 &&
                            <Box className={styles.minMax}>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: 11,
                                  fontWeight: 700
                                }}>
                                Min.{playedCoupon.bonus_min.toFixed(2)}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: 11,
                                  fontWeight: 700
                                }}>
                                Max.{playedCoupon.bonus_max.toFixed(2)}
                              </Typography>
                            </Box>
                          }
                        </Box>

                        {/*min win*/}
                        {playedCoupon?.coupon_type.id !== 1 && playedCoupon?.coupon_type.id !== 2 &&
                          <Box>
                            <Box
                              sx={{
                                border: "0.1px solid #000000",
                                textAlign: "center"
                              }}>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: 12,
                                  fontWeight: 700,
                                  textTransform: "uppercase"
                                }}>
                                <Translate value={"coupon.potentialWin"} />
                                COL.MIN
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                borderBottom: "0.1px solid #000000",
                                borderLeft: "0.1px solid #000000",
                                borderRight: "0.1px solid #000000",
                                textAlign: "center"
                              }}>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: 12,
                                  fontWeight: 700,
                                  textTransform: "uppercase",
                                  textAlign: "center"
                                }}>
                                {(playedCoupon.min_potential_win / divider).toFixed(decimal)}{skinCurrency}
                              </Typography>
                            </Box>
                          </Box>
                        }
                        {/*max win*/}
                        {playedCoupon?.coupon_type.id !== 1 && playedCoupon?.coupon_type.id !== 2 &&
                          <Box>
                            <Box
                              sx={{
                                border: "0.1px solid #000000",
                                textAlign: "center"
                              }}>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: 12,
                                  fontWeight: 700,
                                  textTransform: "uppercase"
                                }}>
                                <Translate value={"coupon.potentialWin"} />
                                COL.MAX
                              </Typography>
                            </Box>
                            <Box sx={{ border: "0.1px solid #000000", textAlign: "center" }}>
                              <Typography
                                sx={{
                                  color: "#000000",
                                  fontSize: 12,
                                  fontWeight: 700,
                                  textTransform: "uppercase",
                                  textAlign: "center"
                                }}>
                                {(playedCoupon.max_potential_win / divider).toFixed(decimal)}{skinCurrency}
                              </Typography>
                            </Box>
                          </Box>
                        }
                      </>
                    }
                    <Box
                      sx={{
                        border: "0.1px solid #000000",
                        textAlign: "center"
                      }}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 12,
                          fontWeight: 700,
                          textTransform: "uppercase"
                        }}>
                        <Translate value={"coupon.potentialWin"} />
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: "0.1px solid #000000",
                        borderLeft: "0.1px solid #000000",
                        borderRight: "0.1px solid #000000",
                        textAlign: "center"
                      }}>
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 17,
                          fontWeight: 700
                        }}>
                        {(playedCoupon.win_max / divider).toFixed(decimal)}{skinCurrency}
                      </Typography>
                    </Box>
                  </Box>
                  {!playedCoupon.running &&
                    <Box sx={{ border: "0.1px solid #000000" }}>
                      <Box sx={{ borderBottom: "0.1px solid #000000", textAlign: "center" }}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontSize: 13,
                            fontWeight: 700,
                            textTransform: "uppercase"
                          }}>
                          <Translate value={`coupon.${playedCoupon.cancelled ? "cancelled"
                            : playedCoupon.winner && playedCoupon.wincashout ? "cashout"
                              : playedCoupon.winner && !playedCoupon.wincashout ? "winner"
                                : !playedCoupon.winner ? "loser" : ""}`
                          }
                          />
                        </Typography>
                      </Box>
                      {!playedCoupon.cancelled && playedCoupon.winner &&
                        <Box sx={{ borderBottom: "0.1px solid #000000", textAlign: "center" }}>
                          <Typography
                            sx={{
                              color: "#000000",
                              fontSize: 17,
                              fontWeight: 700
                            }}>
                            {(playedCoupon.win_amount / divider).toFixed(decimal)}{skinCurrency}
                          </Typography>
                        </Box>
                      }
                    </Box>
                  }
                  {playedCoupon.played &&
                    <Box className={styles.barcodeContainer} sx={{ border: "0.1px solid #000000" }}>
                      <Barcode value={playedCoupon.serial} displayValue={false} />
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 17,
                          fontWeight: 700
                        }}>
                        {playedCoupon.serial}
                      </Typography>
                    </Box>
                  }
                  {playedCoupon.user_confirm &&
                    <Box className={styles.confirmGroup}>
                      <Typography sx={{ color: "#000000", fontSize: 16, fontWeight: 700 }}>{counter}</Typography>
                      <Box className={styles.buttonGroup}>
                        <Button
                          startIcon={<FontAwesomeIcon type="fas" name="xmark" />}
                          variant="contained"
                          color="error"
                          onClick={() => handleCouponRefuse(playedCoupon.id)}
                          className={styles.buttonRequired}
                        >
                          <Translate value="coupon.refuse" />
                        </Button>
                        <Button
                          startIcon={<FontAwesomeIcon type="fas" name="check" />}
                          variant="contained"
                          color="success"
                          onClick={() => handleCouponConfirm(playedCoupon.id)}
                          className={styles.buttonRequired}
                        >
                          <Translate value="coupon.accept" />
                        </Button>
                      </Box>
                    </Box>
                  }
                </>
              }
            </Box>

        }
      </Page>
    </Document>
  )
}
